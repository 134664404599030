import React, { useEffect, useState } from "react";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { getInputData } from "../../ui/Input/input-utils";
import { ETemplateValueType } from "./EmailTemplater";

interface IProps {
	inputId: string;
	property: string[];
	onUpdate: (value: string, inputId: string, type: ETemplateValueType) => void
}

enum EInputs {
	token = "token"
}

const EmailTemplateTokenInput: React.FC<IProps> = ({ inputId, property, onUpdate }) => {

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.token]: {
			type: EInputType.select,
			value: "",
			options: property.map((item) => { return { value: item, label: item } }),
			label: "Token",
		}
	})

	useEffect(() => {
		const { token } = getInputData<{ token: string }>(inputs);
		onUpdate(token, inputId, ETemplateValueType.token);
	}, [inputs, inputId, onUpdate])

	const createInput = useCreateInput(inputs, setInputs);

	return <>
		{createInput(EInputs.token)}
	</>
}

export default EmailTemplateTokenInput;