import React from "react";

import classes from "./Tabs.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

export interface ITab {
	title: ETranslation;
	path: string;
	visible: boolean;
}

interface IProps {
	tabs: ITab[];
}

const Tabs: React.FC<IProps> = ({ tabs }) => {
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	return (
		<ul className={classes.Tabs}>
			{tabs.map((tab) => {
				if (!tab.visible) return null;
				const classNames: string[] = [];
				if (location.pathname.includes(tab.path)) {
					classNames.push(classes.ActiveTab);
				}
				return (
					<li key={tab.path} className={classNames.join(" ")} onClick={() => history.push(tab.path)}>
						{t(tab.title)}
					</li>
				);
			})}
		</ul>
	);
};

export default Tabs;
