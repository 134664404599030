import React, { useRef, useState } from "react";
import { IOption } from "../Input/Input";
import { ActionMeta, SingleValue, MultiValue } from "react-select";
import ReactAsyncSelect from 'react-select/async';
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

export const DEFAULT_SEARCH_TERM_MIN = 3;

export type TFetchOption = (
	searchTerm: string,
	signal: AbortSignal
) => Promise<IOption[]>;

interface IProps {
	className: string,
	value: IOption | IOption[];
	placeholder?: string;
	multiple?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	onChange: (value: IOption | IOption[]) => void;
	fetchOptions?: TFetchOption;
}

const SelectSearch: React.FC<IProps> = ({ className, value, isLoading, onChange, placeholder, multiple, disabled, fetchOptions }) => {
	const abortController = useRef<null | AbortController>();
	const searchTimeout = useRef<null | number>(null);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const changeHandler = (value: SingleValue<IOption> | MultiValue<IOption>, actionMeta: ActionMeta<IOption>) => {
		if (multiple) {
			onChange(value as IOption[])
		} else {
			onChange(value as IOption);
		}
	}

	const promiseOptions = (input: string) => {
		if (!fetchOptions) return Promise.resolve([]);
		if (abortController.current) abortController.current.abort();
		if (searchTimeout.current) clearTimeout(searchTimeout.current);
		if (input.length < 3) {
			return Promise.resolve([]);
		}
		setLoading(true);
		try {
			abortController.current = new AbortController();
			return fetchOptions(input, abortController.current.signal);
		} catch (error) {
			console.error("Error: SearchHandler in SelectSearch");
		} finally {
			setLoading(false);
		}
	}
	return (
		<ReactAsyncSelect
			onChange={changeHandler}
			cacheOptions
			loadOptions={promiseOptions}
			placeholder={placeholder}
			isLoading={loading || isLoading}
			isMulti={multiple}
			value={value}
			isDisabled={disabled}
			noOptionsMessage={(input) => {
				const length = input.inputValue.length;
				if (length === 0) {
					return t(ETranslation.SELECT_ENTER_SEARCH_TERM);
				} else if (length >= 1 && length <= 2) {
					return t(ETranslation.SELECT_ENTER_ATLEAST_CHARS);
				}
				return t(ETranslation.SELECT_NO_RESULTS);
			}}
			isClearable
		/>
	);
};
export default SelectSearch;
