import React from "react";
import AttributesAdd from "../../../components/Attributes/AttributesAdd";
import { IAttribute } from "../../../interfaces/IAttribute";
import { ECategoryType } from "../../../shared/category-data";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

interface IProps {
	attributes: IAttribute[];
	setAttributes: React.Dispatch<React.SetStateAction<IAttribute[]>>;
}

const InterpretationAddFormAttributes: React.FC<IProps> = ({ attributes, setAttributes }) => {
	const { t } = useTranslation();
	return (
		<div>
			<label style={{ display: "block", marginBottom: ".25rem" }}>{t(ETranslation.INTERPRETATION_ATTRIBUTES)}</label>
			<AttributesAdd
				attributes={attributes}
				setAttributes={setAttributes}
				buttonText={t(ETranslation.INTERPRETATION_ADD_ATTRIBUTE)}
				title={t(ETranslation.INTERPRETATION_ADD_ATTRIBUTE)}
				types={[ECategoryType.INTERPRETATION_SEARCH]}
			/>
		</div>
	);
};

export default InterpretationAddFormAttributes;
