import { User } from "../classes/User";
import { EStorageKeys } from "../shared/storage-keys";



export class LocalStorage {
	private _key: any;
	private DSprefix: string;


	constructor(user: User) {
		this.DSprefix = user.site?.name + "_" + user.email;
	}

	private getDataKey(key: EStorageKeys): string {
		return this.DSprefix + "_" + key;
	}

	public getData(key: EStorageKeys): string | null {
		return localStorage.getItem(this.getDataKey(key));
	}

	public setData(key: EStorageKeys, data: any) {
		localStorage.setItem(this.getDataKey(key), JSON.stringify(data));
	}

	public clearData(key: EStorageKeys) {
		localStorage.removeItem(this.getDataKey(key));
	}

	// TODO(Joonas): Encyprtion and Decryption with builtin crypto module
	// Node version 22 lts has everything builtin to do this

}