import React, { useEffect, useState } from "react";
import { IUser } from "../../classes/User";
import { EFetchMethod, customFetch } from "../../custom-fetch";
import Spinner from "../../components/ui/Spinner/Spinner";
import UserCommentItem from "../../components/User/UserComment";
import CommentingElement from "../../components/User/NewUserComment";
import { IOrganizationGroup } from "../../interfaces/IOrganizationGroup";
import { IOrganization } from "../../interfaces/IOrganization";
import { dateToDateTimeString } from "../../components/ui/Input/input-utils";

interface IProps {
	user: IUser;
}

export interface IComment {
	comment: string;
	commentText: string;
	user: IUser;
	createdBy: IUser;
	siteId: string;
	updated: string;
	created: string;
	id: string;
	organization: IOrganization;
	organizationGroup: IOrganizationGroup;
}

// Tästä voisi ehkä tehdä ihan oman komponenttinsa kirjastoon jotta sitä voidaan uudelleenkäyttää "oikeasta paikasta"
const UserComments: React.FC<IProps> = ({ user }) => {
	const [loading, setLoading] = useState(false);

	const [comments, setComments] = useState<null | IComment[]>(null);

	const getUserComments = (): Promise<IComment[]> => {
		return new Promise((resolve, reject) => {
			customFetch<IComment[]>("/v2/comments/listbyuser", EFetchMethod.POST, JSON.stringify({ userId: user.id }))
				.then((result) => {
					resolve(result);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const getCommentsForUser = () => {
		setLoading(true);
		getUserComments().then((result) => {
			setComments(result);
			setLoading(false);
		});
	};
	useEffect(() => {
		getCommentsForUser();
		// TODO Find a better solution for the onMount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addHandler = (comment: string) => {
		const testData = {
			userId: user.id,
			commentText: comment,
		};
		customFetch<IUser>("/v2/comments/add", EFetchMethod.POST, JSON.stringify(testData)).then((result) => {
			getCommentsForUser();
		});
	};

	return loading ? (
		<Spinner />
	) : (
		<div>
			{comments?.map((comment, key) => {
				const commentLabel = [comment.createdBy.name, dateToDateTimeString(new Date(comment.created))].join(
					" - "
				);
				return <UserCommentItem key={key} label={commentLabel} value={comment.commentText} />;
			})}
			<CommentingElement onAdd={addHandler} />
		</div>
	);
};

export default UserComments;
