import { IOption } from "../components/ui/Input/Input";

// poimittu wanhasta administa 13.6.2024
export const LOCATION_OPTIONS: IOption[] = [
	{ value: "ahvenanmaa", label: "Ahvenanmaa" },
	{ value: "etela-karjala", label: "Etelä-Karjala" },
	{ value: "etela-pohjanmaa", label: "Etelä-Pohjanmaa" },
	{ value: "etela-savo", label: "Etelä-Savo" },
	{ value: "kainuu", label: "Kainuu" },
	{ value: "kanta-hame", label: "Kanta-Häme" },
	{ value: "keski-pohjanmaa", label: "Keski-Pohjanmaa" },
	{ value: "keski-suomi", label: "Keski-Suomi" },
	{ value: "kymenlaakso", label: "Kymenlaakso" },
	{ value: "lappi", label: "Lappi" },
	{ value: "pirkanmaa", label: "Pirkanmaa" },
	{ value: "pohjanmaa", label: "Pohjanmaa" },
	{ value: "pohjois-karjala", label: "Pohjois-Karjala" },
	{ value: "pohjois-pohjanmaa", label: "Pohjois-Pohjanmaa" },
	{ value: "pohjois-savo", label: "Pohjois-Savo" },
	{ value: "paijat-hame", label: "Päijät-Häme" },
	{ value: "satakunta", label: "Satakunta" },
	{ value: "uusimaa", label: "Uusimaa" },
	{ value: "varsinais-suomi", label: "Varsinais-Suomi" },
	{ value: "komentajankatu5", label: "ESPOO, Komentajankatu 5" },
	{ value: "upseerinkatu3", label: "ESPOO, Upseerinkatu 3" },
	{ value: "suomenlahdentie1", label: "ESPOO, Suomenlahdentie 1" },
	{ value: "mannerheiminkatu20", label: "PORVOO, Mannerheiminkatu 20" },
	{ value: "elannontie3", label: "VANTAA, Elannontie 3 " },
	{ value: "vernissakatu1", label: "VANTAA, Vernissakatu 1 " },
	{ value: "lummetie2b", label: "VANTAA, Lummetie 2b" },
	{ value: "puuvalonaukio2", label: "KERAVA, Puuvalonaukio 2" },
	{ value: "aleksiskiventie4", label: "KERAVA, Aleksis Kiven tie 4" },
	{ value: "kauppakaari11", label: "KERAVA, Kauppakaari 11" },
	{ value: "asiakkaankatu3", label: "HELSINKI, Asiakkaankatu 3" },
	{ value: "asemapaallikonkatu11", label: "HELSINKI, Asemapäällikönkatu 11, Pasila" },
	{ value: "ratapihantie7", label: "HELSINKI, Ratapihantie 7" },
	{ value: "kankurinkatu4-6", label: "HYVINKÄÄ, Kankurinkatu 4-6 " },
	{ value: "suutarinkatu2", label: "HYVINKÄÄ, Suutarinkatu 2" },
	{ value: "urakankatu1", label: "HYVINKÄÄ, Urakankatu 1" },
	{ value: "mannilantie28", label: "JÄRVENPÄÄ, Mannilantie 28" },
	{ value: "antinkatu1", label: "HELSINKI, Antinkatu 1" },
	{ value: "runeberginkatu5b", label: "HELSINKI, Runeberginkatu 5B" },
	{ value: "viipurinkatu2", label: "HELSINKI, Viipurinkatu 2" },
	{ value: "lintulahdenkuja2", label: "HELSINKI, Lintulahdenkuja 2" },
	{ value: "malminkatu34", label: "HELSINKI,  Malminkatu 34" },
	{ value: "otakaari5", label: "ESPOO, Otakaari 5" },
	{ value: "leppavaarankatu3-9", label: "ESPOO, Leppävaarankatu 3-9" },
	{ value: "piispanportti10", label: "ESPOO, Piispanportti 10" },
	{ value: "nummentie12-16", label: "LOHJA, Nummentie 12-16" },
	{ value: "elinakurjenkatu11", label: "KARJAA, Elina Kurjenkatu 11" },
	{ value: "kulmakatu6", label: "HANKO, Kulmakatu 6" },
	{ value: "kartanonkatu10", label: "FORSSA, Kartanonkatu 10" },
	{ value: "vanainkatu1", label: "HÄMEENLINNA, Vanainkatu 1" },
	{ value: "turunkatu4", label: "HÄMEENLINNA, Turunkatu 4 Ohjaamo" },
	{ value: "raatihuoneenkatu21-23", label: "HÄMEENLINNA, Business parkki, Raatihuoneenkatu 21-23" },
	{ value: "hoyrysahanraitti1d", label: "HÄMEENLINNA, Höyrysahanraitti 1D" },
	{ value: "kirkkokatu12", label: "LAHTI, Kirkkokatu 12" },
	{ value: "palvelutori", label: "LAHTI, TRIO Palvelutori" },
	{ value: "aleksanterinkatu24", label: "LAHTI, Aleksanterinkatu 24" },
	{ value: "temppelikatu2", label: "RIIHIMÄKI, Temppelikatu 2 " },
	{ value: "kaartokatu2a", label: "RIIHIMÄKI BusinessPark, Kaartokatu 2A" },
	{ value: "satamantie4", label: "HAMINA, Satamantie 4" },
	{ value: "tainionkoskentie1", label: "IMATRA, Tainionkoskentie 1 (Idänportti)" },
	{ value: "tainionkoskentie70", label: "IMATRA, Tainionkoskentie 70" },
	{ value: "laivurinkatu1-3", label: "KOTKA, Laivurinkatu 1-3" },
	{ value: "salpausselankatu26", label: "KOUVOLA, Salpausselänkatu 26, KymenLukon talo" },
	{ value: "koulukatu5", label: "LAPPEENRANTA, Koulukatu 5 " },
	{ value: "pormestarinkatu1", label: "LAPPEENRANTA, Pormestarinkatu 1" },
	{ value: "kirkkotie6", label: "SAVITAIPALE, Kirkkotie 6 " },
	{ value: "villimiehenkatu2b", label: "LAPPEENRANTA, Villimiehenkatu 2 B" },
];

export const getLocationString = (value: string) => {
	return LOCATION_OPTIONS.find((location) => location.value === value)?.label;
};
