import React, { useEffect, useState } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import Heading from '../Heading/Heading';
import { IAttributeSettings } from '../../interfaces/IAttributeSettings';
import { EInputType, IInputField } from '../ui/Input/Input';
import { getInputData, initForm, validateInputs } from '../ui/Input/input-utils';
import { ETranslation } from '../../translations/translation-keys';
import { useTranslation } from 'react-i18next';

enum EInputs {
	isRequired = "isRequired",
}

interface IProps {
	onChange: (settings: IAttributeSettings, isValid: boolean) => void;
	settings: IAttributeSettings | null;
	loading?: boolean;
	showValidation?: boolean;
}

const AttributeSettings: React.FC<IProps> = ({ onChange, settings, loading, showValidation }) => {
	const { t } = useTranslation();
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.isRequired]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.COMMON_MANDATORY,
			value: "",
			validation: {
				required: true,
			},
		},
	});

	useEffect(() => {
		if (settings) {
			initForm(setInputs, settings);
		}
	}, [settings]);

	useEffect(() => {
		const settings = getInputData<IAttributeSettings>(inputs);
		const isValid = validateInputs(inputs);
		onChange(settings, isValid);
	}, [inputs, onChange]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	return (
		<div>
			<Heading tag="h2">{t(ETranslation.CATEGORY_SETTINGS)}</Heading>
			{createInput(EInputs.isRequired)}
		</div>
	);
};

export default AttributeSettings;
