import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Container from '../../components/ui/Container/Container';
import { ESitePrefix } from '../../interfaces/ISite';

interface IMatch {
	prefix: ESitePrefix;
}

interface IProps extends RouteComponentProps<IMatch> {}


const PageNotFoundPage: React.FC<IProps> = ({ match }) => {
	return (
		<Container
			style={{
				maxWidth: 800,
				margin: "1rem auto",
				borderRadius: ".5rem",
				textAlign: "center",
			}}
		>
			<h1>Sivua ei löytynyt.</h1>
		</Container>
	);
};

export default PageNotFoundPage;
