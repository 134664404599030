import { createContext, Dispatch, SetStateAction } from "react";

export const createListSelectContext = function <T>() {
	interface ISelectedItemsContext {
		selectedRows: T[];
		setSelectedRows: Dispatch<SetStateAction<T[]>>;
		selectAll: boolean;
		setSelectAll: Dispatch<SetStateAction<boolean>>;
	}

	const defaultContext: ISelectedItemsContext = {
		selectedRows: [],
		setSelectedRows: () => { },
		selectAll: false,
		setSelectAll: () => { },
	}

	const SelectedItemsContext = createContext<ISelectedItemsContext>(defaultContext);

	return { SelectedItemsContext };
};
