import React from 'react';

import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';


interface IProps {
	value: string;
	onChange: (value: string) => void;
	disabled?: boolean;
}

const modules = {
	toolbar: [
		[{ 'header': [1, 2, false] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
		['link', 'image'],
		['clean']
	],
}

const WysiwygEditor: React.FC<IProps> = ({ value, onChange, disabled }) => {
	return (
		<ReactQuill theme={"snow"} value={value} onChange={onChange} modules={modules} />
	)
}

export default WysiwygEditor;