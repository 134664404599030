import React, { useState } from "react";
import { ISendgridEmailTemplate } from "../../../interfaces/ISendgridTemplate";
import EmailTemplateInput from "./EmailTemplateTypeSelectInput";
import Button from "../../ui/Button/Button";
import { sendSendgridMail } from "../../../store/actions/sendgridTemplateActions";
import { useDispatch } from "react-redux";
import { EmailTemplaterPreviewFactory } from "./EmailTemplaterPreview";
import { useMailTemplater } from "./useMailTemplater";


interface IProps<T = unknown> {
	template: ISendgridEmailTemplate;
	items: T[];
	sending: () => void;
}

export interface ITemplateValues {
	[key: string]: ITemplateValue
}

export interface ITemplateValue {
	value: string;
	type: ETemplateValueType;
}

export enum ETemplateValueType {
	token = "token",
	text = "text"
}


export const EmailtTemaplterFactory = <T extends any>() => {
	const Instance: React.FC<IProps<T>> = ({ template, items, sending }) => {

		const dispatch = useDispatch();

		const { keysInTemplate, propsArray } = useMailTemplater<T>(items[0], template)
		const [templateVals, setTemplateVals] = useState<ITemplateValues>({});

		const onUpdate = (value: string, id: string, type: ETemplateValueType) => {
			let existingValue = templateVals ? templateVals[id] ? templateVals[id].value ?? false : false : false;
			let existingType = templateVals ? templateVals[id] ? templateVals[id].type ?? false : false : false;
			// valuet joko false tai olemssa oleva. Ei päivitetä jos ei muutoksia, vaan render input muutos
			if (existingValue !== value || existingType !== type) {
				setTemplateVals((t) => {
					return {
						...t,
						[id]: {
							...t[id],
							value: value,
							type: type
						}
					}
				});
				return;
			}
		}
		const send = () => {
			dispatch(sendSendgridMail(templateVals, items, template.template_id))
			sending();
		}

		const previewState = useState(false);
		const Preview = EmailTemplaterPreviewFactory<T>({ previewState });

		return <>
			<h4>
				Viesti tarvitsee {keysInTemplate()?.length} parametriä, <b>{keysInTemplate()?.join(", ")}</b>, jotka täytyy määritellä alla.
				Voit käyttää "tokenia", jolloin järjestelmä käyttää jotain annetun datan tietuetta, tai kirjoittaa parametrin käsin.
			</h4>
			{keysInTemplate()?.map((input, key) => {
				return <EmailTemplateInput key={key} inputId={input} property={propsArray()} onUpdate={onUpdate} />
			})}
			<h4>
				Myös lähettäjä ja vastaanottaja täytyy määritellä, sekä antaa viestin aihe
			</h4>
			<EmailTemplateInput inputId={"emailFrom"} property={propsArray()} onUpdate={onUpdate} />
			<EmailTemplateInput inputId={"emailTo"} property={propsArray()} onUpdate={onUpdate} />
			<Preview mailTemplate={template} values={templateVals} item={items[0]} />
			<Button onClick={send}>Send</Button>
		</>
	}
	return Instance
}