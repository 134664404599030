import { faClipboard, faClipboardCheck, faEye, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { EMessageContentType, Message } from '../../../classes/Message';
import { IAttachment } from '../../../interfaces/IAttachment';
import IAppState from '../../../interfaces/store/IAppState';
import * as attachmentService from '../../../services/attachmentService';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import { useFilePreviewModal } from '../../FilePreview/useFilePreviewModal';
import Button, { EButtonColor, EButtonSize } from '../../ui/Button/Button';
import Spinner from '../../ui/Spinner/Spinner';
import classes from './MessageShowContent.module.scss';

interface IProps {
	message: Message;
}

const MessageShowContent: React.FC<IProps> = ({ message }) => {
	const [objectUrl, setObjectUrl] = useState<string | null>(null);
	const [loadingAttachment, setLoadingAttachment] = useState(false);
	const [isCopied, setCopied] = useState(false);
	const openPreviewModal = useFilePreviewModal();
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { attachment, loading } = useSelector((state: IAppState) => state.attachment);

	const tryAutoLoad = useCallback((): boolean => {
		const messageAttachment = message.attachment;
		const messageContentType = message.contentType;
		if (messageAttachment) {
			if (messageContentType === EMessageContentType.AUDIO) return true;
			const attachmentContentType = messageAttachment.contentType;
			return attachmentContentType ? attachmentContentType.indexOf("image") === 0 : false;
		}
		return false;
	}, [message]);

	useEffect(() => {
		const messageAttachment = message.attachment;
		if (messageAttachment) {
			if (tryAutoLoad()) {
				dispatch(actions.getAttachment(messageAttachment.id));
			}
		}
		return () => {
			dispatch(actions.getAttachmentClear());
		};
	}, [tryAutoLoad, message, dispatch]);

	useEffect(() => {
		if (objectUrl) {
			return () => {
				URL.revokeObjectURL(objectUrl);
			};
		}
	}, [objectUrl]);

	const downloadHandler = useCallback(
		async (messageAttachment: IAttachment) => {
			try {
				setLoadingAttachment(true);
				const attachment = await attachmentService.getAttachment(messageAttachment.id);
				if (attachment) {
					if (tryAutoLoad()) {
						(async () => {
							const blob = await fetch(attachment.location).then((resp) => resp.blob());
							setObjectUrl(URL.createObjectURL(blob));
						})();
					} else {
						window.open(attachment.location);
					}
				}
			} catch (e) {
			} finally {
				setLoadingAttachment(false);
			}
		},
		[tryAutoLoad]
	);

	const viewHandler = useCallback(async (messageAttachment: IAttachment) => {
		try {
			setLoadingAttachment(true);
			const attachment = await attachmentService.getAttachment(messageAttachment.id);
			if (attachment) {
				openPreviewModal(attachment.location, attachment.contentType);
			}
		} catch (e) {
		} finally {
			setLoadingAttachment(false);
		}
	}, [openPreviewModal]);

	const renderAudio = () => {
		if (!objectUrl) return null;
		return (
			<audio controls className={classes.AudioPlayer}>
				<source src={objectUrl} type={attachment?.contentType} />
			</audio>
		);
	};

	const textToClipboardHandler = (text: string) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				setCopied(true);
				setTimeout(() => {
					setCopied(false);
				}, 2000);
			})
			.catch(() => {
				alert(t(ETranslation.MESSAGE_COPY_FAILED));
			});
	};

	const renderText = () => {
		return (
			<>
				<p className={classes.Text}>{message.textContentText}</p>
				<Button
					onClick={() => textToClipboardHandler(message.textContentText || "")}
					size={EButtonSize.SMALL}
					color={EButtonColor.DEFAULT}
				>
					<FontAwesomeIcon icon={isCopied ? faClipboardCheck : faClipboard} />
				</Button>
			</>
		);
	};

	const renderFile = () => {
		const messageAttachment = message.attachment;
		if (objectUrl) {
			return (
				<img
					onClick={() => window.open(objectUrl)}
					src={objectUrl}
					alt={attachment?.clientName}
					style={{ maxHeight: "200px", cursor: "pointer" }}
				/>
			);
		} else if (messageAttachment) {
			return (
				<div style={{ display: "flex", gap: "1rem" }}>
					<div className={classes.DownloadContainer} onClick={() => downloadHandler(messageAttachment)}>
						<FontAwesomeIcon icon={faFileDownload} />
					</div>
					<div className={classes.DownloadContainer} onClick={() => viewHandler(messageAttachment)}>
						<FontAwesomeIcon icon={faEye} />
					</div>
				</div>
			);
		}
	};

	if (loadingAttachment || loading) {
		return <Spinner />;
	}

	return (
		<div className={classes.Container}>
			{(() => {
				switch (message.contentType) {
					case EMessageContentType.AUDIO:
						return renderAudio();
					case EMessageContentType.TEXT:
						return renderText();
					case EMessageContentType.FILE:
						return renderFile();
				}
			})()}
		</div>
	);
};

export default MessageShowContent;
