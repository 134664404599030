import React from "react";
import { ILead } from "../../../interfaces/ILead";
import { useMailTemplateSelectionModal } from "../../Sendgrid/MailTemplateSelection/useMailTemplateSelectionModal";
import Button from "../../ui/Button/Button";
import classes from './leads.module.scss';
import { useDispatch } from "react-redux";
import { updateSentMails } from "../../../store/actions/leadsActions";

interface IProps {
	leads: ILead[];
}

const LeadsSendMailButton: React.FC<IProps> = ({ leads }) => {
	const openModal = useMailTemplateSelectionModal(leads);
	const dispatch = useDispatch();
	const sendMails = () => {
		openModal(leads).then((result) => {
			dispatch(updateSentMails(result.items, result.template))
		})
	}
	return <Button className={classes.sendMailBtn} onClick={sendMails}> send mail</Button>
}

export default LeadsSendMailButton;