import React from "react";
import { ISentMail } from "../../interfaces/ISentMail";
import classes from "./SentMail.module.scss"
import SentMail from "./SentMail";

interface IProps {
	sentMails: ISentMail[];
	colSpan?: number;
}

const SentMailsColumn: React.FC<IProps> = ({ sentMails, colSpan }) => {
	return <td colSpan={colSpan}>
		<div className={classes.infoContainer}>
			{sentMails.map((mail, key) => {
				return <SentMail key ={key} sentMail={mail} />
			})}
		</div>
	</td>
}

export default SentMailsColumn;