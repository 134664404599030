import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAttributeOptions } from '../../../hooks/useAttributeOptions';
import { IAttribute } from '../../../interfaces/IAttribute';
import { ILanguage } from '../../../interfaces/ILanguage';
import IAppState from '../../../interfaces/store/IAppState';
import ILanguageState from '../../../interfaces/store/ILanguageState';
import { ETranslation } from '../../../translations/translation-keys';
import { useAttributesAttach } from '../../Attributes/useAttributesAttach';
import { useAttributeSelectModal } from '../../Attributes/useAttributeSelectModal';
import Button, { EButtonColor, EButtonSize } from '../../ui/Button/Button';
import Table from '../../ui/Table/Table';
import { IOption } from '../../ui/Input/Input';
import { ECategoryType } from '../../../shared/category-data';
import BadgeList from '../../ui/BadgeList/BadgeList';

interface IProps {
	toLanguages: ILanguage[];
	onAttributesChange?: (languageCode: string, attributes: IAttribute[]) => void;
	onDelete?: (languageCode: string) => void;
}

const LanguageTable: React.FC<IProps> = ({ toLanguages, onDelete, onAttributesChange }) => {
	const { t } = useTranslation();

	if (!toLanguages || !toLanguages.length) return null;
	return (
		<Table>
			<thead>
				<tr>
					<th>{t(ETranslation.LANGUAGE_LANGUAGE_CODE)}</th>
					<th>Ominaisuudet</th>
					<th>{t(ETranslation.SITE_ACTIVE)}</th>
					{onAttributesChange && <th></th>}
					{onDelete && <th></th>}
				</tr>
			</thead>
			<tbody>
				{toLanguages.map((language) => (
					<Item
						key={language.languageCode}
						language={language}
						onDelete={onDelete}
						onAttributesChange={onAttributesChange}
					/>
				))}
			</tbody>
		</Table>
	);
};

interface IItemProps {
	language: ILanguage;
	onDelete?: (languageCode: string) => void;
	onAttributesChange?: (languageCode: string, attributes: IAttribute[]) => void;
}

const Item: React.FC<IItemProps> = ({ language, onDelete, onAttributesChange }) => {
	const { t } = useTranslation();
	const { languages, unlinkLoading, id } = useSelector<
		IAppState,
		ILanguageState
	>((state) => state.language);

	const { attributeOptions } = useAttributeOptions<IOption>([ECategoryType.LANGUAGE_PAIR_SEARCH]);

	const languageCode = language.languageCode;

	const attributesChangeHandler = useCallback((attributes: IAttribute[]) => {
		if (onAttributesChange) {
			onAttributesChange(languageCode, attributes);
		}
	}, [languageCode, onAttributesChange]);

	const { attributes, attributesAddHandler, attributesDeleteHandler, initAttributes } =
		useAttributesAttach({ onChange: attributesChangeHandler});

	const openAttributeSelectModal = useAttributeSelectModal();

	const addAttributeHandler = async () => {
		const attr = await openAttributeSelectModal(
			attributes,
			t(ETranslation.ATTRIBUTES_ADD_ATTRIBUTE),
			[ECategoryType.LANGUAGE_PAIR_SEARCH]
		);
		if (attr) {
			attributesAddHandler(attr);
		}
	};

	useEffect(() => {
		if (language) {
			initAttributes(language.attributes);
		}
	}, [language, initAttributes]);

	return (
		<tr key={languageCode}>
			<td>
				{`${
					languages?.find(
						(l) => l.languageCode === languageCode
					)?.name ?? ""
				} (${languageCode})`}
			</td>
			<td>
				<div style={{ display: "flex", gap: ".5rem" }}>
					{attributeOptions.length > 0 && <BadgeList items={attributes.map(attr => ({...attr, name: attributeOptions.find(option => option.value === attr.id)?.label ?? ''}))} onDelete={attributesDeleteHandler} />}
				</div>
			</td>
			<td style={{ width: "1px", textAlign: "center" }}>
				{language.active && <FontAwesomeIcon icon={faCheckCircle} />}
			</td>
			{onAttributesChange && (
				<td style={{ width: "1px", whiteSpace: "nowrap" }}>
					<Button onClick={addAttributeHandler} size={EButtonSize.SMALL}>
						{t(ETranslation.ATTRIBUTES_ADD_ATTRIBUTE)}
					</Button>
				</td>
			)}
			{onDelete && (
				<td width="1px" style={{ whiteSpace: "nowrap" }}>
					<Button
						color={EButtonColor.DANGER}
						size={EButtonSize.SMALL}
						loading={unlinkLoading && id === language.languageCode}
						onClick={() => onDelete(language.languageCode)}
					>
						{t(ETranslation.COMMON_DELETE)}
					</Button>
				</td>
			)}
		</tr>
	);
};

export default LanguageTable;
