import React from "react";
import { EUserRole, IUser } from "../../classes/User";
import { ETranslation } from "../../translations/translation-keys";
import Table from "../ui/Table/Table";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IAppState from "../../interfaces/store/IAppState";
import IAuthState from "../../interfaces/store/IAuthState";
import UsersListItem from "./UsersList/UsersListItem";
import { RouteComponentProps } from "react-router-dom";
import { IOption } from "../ui/Input/Input";
import { ERoute } from "../../classes/Routes";
import { createOptions } from "../../utils/option-utils";
import { useTableSort } from "../ui/Table/useTableSort";
import TableSortContext from "../ui/Table/TableSortContext";
import TableTh from "../ui/Table/TableTh/TableTh";
import { EStorageKeys } from "../../shared/storage-keys";

interface IProps extends RouteComponentProps {
	users: IUser[] | null;
	onOpen: (userId: string) => void;
	onComment: (user: IUser, openModalOnClose: boolean) => void;
}

const UserListTable: React.FC<IProps> = ({ users, onOpen, onComment, history }) => {
	const { t } = useTranslation();

	const { onSort, sort, items } = useTableSort<IUser>({ data: users });

	const { user: currentUser } = useSelector<IAppState, IAuthState>((state) => state.auth);
	const isYoupretAdmin = currentUser?.isYoupretAdmin;
	const listedUsersRole = (users && users.length > 0) ? users[0].role : "";

	const showWorkOrders = (user: IUser) => {
		const userInput: IOption = createOptions([user])[0];
		const currentSearchStorage = currentUser?.storageHandler.getData(EStorageKeys.INTERPRETATIONS_SEARCH_PARAMS);
		const storedSearchInputs = currentSearchStorage ? { ...JSON.parse(currentSearchStorage), users: [userInput] } : { users: [userInput] };
		currentUser?.storageHandler.setData(EStorageKeys.INTERPRETATIONS_SEARCH_PARAMS, storedSearchInputs)
		history.push(ERoute.INTERPRETATIONS_LIST);
	}

	return (
		<Table hover={true}>
			<thead>
				<TableSortContext.Provider value={{ onSort, sort }}>
					<tr>
						<TableTh sort="createdDate" type="date" format={"dd.MM.yyyy"}>{t(ETranslation.COMMON_CREATED)}</TableTh>
						<TableTh sort="name">{t(ETranslation.COMMON_NAME)}</TableTh>
						{listedUsersRole === "INTERPRETER" ?
							<>
								<th>{t(ETranslation.SITE_ADDRESS_TITLE)}</th>
								<th>{t(ETranslation.COMMON_ADDITIONAL_INFO)}</th>
							</> :
							<>
								<th>{t(ETranslation.COMMON_PHONENUMBER)}</th>
								<th>{t(ETranslation.COMMON_EMAIL)}</th>
							</>
						}
						<th>{t(ETranslation.MENU_ORGANIZATIONS)}</th>
						{isYoupretAdmin && <th>{t(ETranslation.COMMON_LANGUAGES)}</th>}
						<TableTh sort="lastSeenDate" type="date" format={"dd.MM.yyyy HH.mm"}>{t(ETranslation.USER_LAST_SEEN)}</TableTh>
						{isYoupretAdmin && (
							<>
								{listedUsersRole === EUserRole.INTERPRETER && <TableTh sort="status">{t(ETranslation.USER_STATUS_OF_INTERPRETER)}</TableTh>}
								{listedUsersRole === EUserRole.ADMIN && <th>{t(ETranslation.COMMON_SITE)}</th>}
								<th>Sopimus</th>
								<th>{t(ETranslation.COMMON_COMMENTS)}</th>
							</>
						)}
					</tr>
				</TableSortContext.Provider>
			</thead>
			<tbody>
				{items &&
					items.map((user) => {
						return <UsersListItem key={user.id} user={user} onComment={onComment} onOpen={onOpen} showWorkOrders={showWorkOrders} />
					})}
			</tbody>
		</Table>
	);
};

export default UserListTable;
