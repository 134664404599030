import React, { useCallback, useContext } from "react";
import ModalContext, { EModalSize } from "../../ui/Modal/ModalContext";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import { EmailtTemaplteModalFactory } from "./EmailTemplateSendModal";
import { ISendgridEmailTemplate } from "../../../interfaces/ISendgridTemplate";
import { useDispatch } from "react-redux";
import { sendgridTemplaterClear } from "../../../store/actions/sendgridTemplateActions";

/**
 * Hook to create email templating modal for given generic object array.
 *
 * @param items Generic typed object array to be used to fill the email template
 * @returns Modal promise for email templating
 */
export const useMailTemplateSelectionModal = function <T>(items: T[]) {
	const { setModal, closeModal } = useContext(ModalContext);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const ModalContent = EmailtTemaplteModalFactory<T>();
	const handler = useCallback(async (items: T[]) => {
		return new Promise<{ items: T[], template: ISendgridEmailTemplate }>((resolve) => {
			setModal({
				isOpen: true,
				size: EModalSize.MEDIUM,
				title: t(ETranslation.EMAIL_TEMPLATE_MODAL_TITLE),
				content: <ModalContent items={items} closeModal={(template: ISendgridEmailTemplate) => { resolve({ items, template }); closeModal(); }} />,
				onModalClose: () => { dispatch(sendgridTemplaterClear()); }
			});
		})
	}, [setModal, t, closeModal, ModalContent, dispatch]);

	return handler;
};
