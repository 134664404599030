import './index.scss';
import './i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore, Reducer } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import { isDevelopment, serverUrl, port } from './config';
import IAppState from './interfaces/store/IAppState';
import * as serviceWorker from './serviceWorker';
import authReducer from './store/reducers/authReducer';
import commonReducer from './store/reducers/commonReducer';
import priceSeasonReducer from './store/reducers/priceSeasonReducer';
import organizationGroupReducer from './store/reducers/organizationGroupReducer';
import attachmentReducer from './store/reducers/attachmentReducer';
import textMessageReducer from './store/reducers/textMessageReducer';
import newsItemReducer from './store/reducers/newsItemReducer';
import siteReducer from './store/reducers/siteReducer';
import messageReducer from './store/reducers/messageReducer';
import infoContactRequestReducer from './store/reducers/infoContactRequestReducer';
import interpretationReducer from './store/reducers/interpretationReducer';
import logItemReducer from './store/reducers/logItemReducer';
import userReducer from './store/reducers/userReducer';
import organizationReducer from './store/reducers/organizationReducer';
import twilioReducer from './store/reducers/twilioReducer';
import sendgridemailsReducer from './store/reducers/sendgridemailsReducer';
import commentReducer from './store/reducers/commentReducer';
import commissionsReducer from './store/reducers/commissionReducers';
import summariesReducer from '../src/store/reducers/summariesReducer'
import tagsReducer from "./store/reducers/tagsReducer";
import reservedDateReducer from "./store/reducers/reservedDateReducer";
import ordersReducer from "./store/reducers/ordersReducer";
import tasksReducer from "./store/reducers/tasksReducer";
import feeReducer from "./store/reducers/feeReducer";
import languageReducer from "./store/reducers/languageReducer";
import workingHoursReducer from "./store/reducers/workingHoursReducer";
import userGroupsReducer from "./store/reducers/userGroupReducer";
import userContractReducer from "./store/reducers/userContractReducer";
import userShiftReducer from "./store/reducers/userShiftReducer";
import eezyTrusterReducer from "./store/reducers/eezyTrusterReducer"
import categoryReducer from "./store/reducers/categoryReducer"
import modalReducer from "./store/reducers/modalReducer"
import attributeReducer from "./store/reducers/attributeReducer"
import netvisorSettingsReducer from "./store/reducers/netvisorSettingsReducer"
import jobApplicationReducer from './store/reducers/jobApplicationReducer';
import emailOrderReducer from './store/reducers/emailOrdersReducer';
import leadsReducer from './store/reducers/leadsReducer';
import sendgridTemplateReducer from './store/reducers/sendgridTemplateReducer';
import resourceReducer from './store/reducers/resourceReducer';

const rootReducer: Reducer<IAppState> = combineReducers({
  auth: authReducer,
  common: commonReducer,
  comments: commentReducer,
  priceSeason: priceSeasonReducer,
  organizationGroup: organizationGroupReducer,
  attachment: attachmentReducer,
  textMessage: textMessageReducer,
  newsItem: newsItemReducer,
  site: siteReducer,
  message: messageReducer,
  infoContactRequest: infoContactRequestReducer,
  interpretation: interpretationReducer,
  logItem: logItemReducer,
  user: userReducer,
  organization: organizationReducer,
  twilio: twilioReducer,
  sendgridemails: sendgridemailsReducer,
  commissions: commissionsReducer,
  tags: tagsReducer,
  reservedDate: reservedDateReducer,
  summaries: summariesReducer,
  orders: ordersReducer,
  tasks: tasksReducer,
  fees: feeReducer,
  language: languageReducer,
  workingHours: workingHoursReducer,
  userGroups: userGroupsReducer,
  userContracts: userContractReducer,
  userShifts: userShiftReducer,
  eezyTruster: eezyTrusterReducer,
  category: categoryReducer,
  modal: modalReducer,
  attribute: attributeReducer,
  netvisorSettings: netvisorSettingsReducer,
  jobApplication: jobApplicationReducer,
  emailOrder: emailOrderReducer,
  leads: leadsReducer,
  sendgridTemplates: sendgridTemplateReducer,
  resource: resourceReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

let BASE_NAME = "";
if(isDevelopment && port === '3000') {
  BASE_NAME = '';
} else if(!isDevelopment && window.location.protocol !== 'https:') {
  window.location.href = `${serverUrl}${BASE_NAME}`;
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={BASE_NAME}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
