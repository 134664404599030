import React from "react";
import { IOrder, saveOrder } from "../../store/actions/ordersActions";
import Badge, { EBadgeType } from "../ui/Badge/Badge";
import InputGroup from "../ui/InputGroup/InputGroup";
import { useDispatch } from "react-redux";
import Classes from "./orders.module.scss";
import { formatDateTime } from "../../utils/date-utils";

interface IProps {
	order: IOrder;
	openOrder: (order: IOrder) => void;
}

const OrderRowItem: React.FC<IProps> = ({ order, openOrder }) => {
	const dispatch = useDispatch();

	const openUser = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		e.stopPropagation();
		window.open(`/users/edit/${order.user?.id}`, "_blank", "noopener, noreferrer")?.focus()
	}

	const addInterpretation = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		e.stopPropagation();
		window.open(`/interpretation/add?orderId=${order.id}`, "_blank", "noopener, noreferrer")?.focus()
	}

	const newCustomer = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		e.stopPropagation();
		window.open(`/users/edit/addCustomer?orderId=${order.id}`, "_blank", "noopener, noreferrer")?.focus()
	}

	const addCustomer = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
		e.stopPropagation();
		dispatch(saveOrder(order))
	}


	let badgeType = EBadgeType.DEFAULT;
	switch (order.status) {
		case "REJECTED":
			badgeType = EBadgeType.DANGER;
			break;

		case "OPEN":
			badgeType = EBadgeType.WARNING;
			break;

		case "COMPLETED":
			badgeType = EBadgeType.SUCCESS;
			break;
		default:
			badgeType = EBadgeType.DEFAULT;
			break;
	}

	return (<tr key={order.id} onClick={() => openOrder(order)}>
		<td>
			<InputGroup>
				<Badge type={badgeType}>
					{order.status}
				</Badge>
			</InputGroup>
		</td>
		<td>{order.createdDateTime}
			{!order.user ?
				<>
					<br />
					<span className={Classes.link} onClick={newCustomer}>Lisää asiakas</span>
					<br />
					<br />
					<span className={Classes.link} onClick={addCustomer}>Yhdistä asiakas</span>
				</>
				:
				<>
					<br />
					<span className={Classes.link} onClick={addInterpretation}>Lisää tulkkaus</span>
				</>
			}
		</td>
		<td>{order.user &&
			<span className={Classes.link} onClick={openUser}>{`${order.user.firstName} ${order.user.lastName}`}</span>
		}
		</td>
		<td>{order.customerFirstName}</td>
		<td>{order.customerLastName}</td>
		<td>{order.customerPhoneNumber}</td>
		<td>{order.customerEmail}</td>
		<td>{order.customerNotes}</td>
		<td>{order.customerOrganizationName}</td>
		<td>{formatDateTime(order.startDateUTC)}</td>
		<td>{order.fromLanguageText}</td>
		<td>{order.toLanguageText}</td>
		<td>{order.durationInMinutes}</td>
	</tr>
	);
};

export default OrderRowItem;
