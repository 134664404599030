export enum ERoute {
  LOG_ITEMS_LIST = '/logitems',
  LOG_ITEM_EDIT = '/logitems/:id',

  JOB_APPLICATION_EDIT = '/jobapplications/:id/:tab',
  JOB_APPLICATION_LIST = '/jobapplications',

  PRICE_SEASONS_LIST = '/priceseasons',
  PRICE_SEASON_EDIT = '/priceseasons/:id',
  PRICE_SEASON_RULES_LIST = '/priceseasonrules',
  PRICE_SEASON_RULE_EDIT = '/priceseasonrules/:id',

  ORGANIZATION_GROUPS_LIST = '/organizationgroups',
  ORGANIZATION_GROUP_EDIT = '/organizationgroups/:id',

  ORGANIZATIONS_LIST = '/organizations',
  ORGANIZATION_EDIT = '/organizations/:id',

  MESSAGE_GROUPS_LIST = '/messagegroups',
  MESSAGE_GROUP_EDIT = '/messagegroups/:id',
  MESSAGE_SHOW = '/message/:id/show',
  MESSAGE_EDIT = '/message/:messageGroupId/:id/edit',
  MESSAGE_ANSWER = '/message/:parentMessageId/:id/answer',

  FEES_LIST = "/fees",
  FEE_EDIT = "/fees/:id",

  SITES_LIST = '/sites',
  SITE_EDIT = '/sites/:id',

  TEXT_MESSAGES_LIST = '/textmessages',
  TEXT_MESSAGE_EDIT = '/textmessages/:id',

  INFO_CONTACT_REQUESTS_LIST = '/infocontactrequests',
  INFO_CONTACT_REQUEST_EDIT = '/infocontactrequests/:id',

  CUSTOMER_REPORTS_LIST = '/customerreports', //added AB 4.2021
  CUSTOMER_REPORT_EDIT = '/customerreports/:id', //added AB 4.2021

  SENDGRID_EMAILS_LOGS='/emailslogs',

  NEWS_ITEMS_LIST = '/newsitems',
  NEWS_ITEM_EDIT = '/newsitems/:id',

  FILE_UPLOAD = '/fileupload',

  ORDER_INTERPRETATION = '/orderinterpretation',
  INTERPRETATION_REVIEW = '/interpretationreview/:id',
  INTERPRETATIONS_LIST = '/interpretations',
  INTERPRETATION_EDIT = '/interpretations/:id',
  INTERPRETATION_PROCESSING = '/interpretationprocessing/:id',
  INTERPRETATION_ADD = '/interpretation/add',

  USER_LOGIN = "/login",

  VIDEO = '/video/:id',

  CHAT = '/chat/:id',

  CALENDAR_PAGE='/calendar',

  ORDER_MODIFY_PAGE='/modifyorder',

  USERS_CUSTOMERS_LIST = '/users/customers',
  USERS_ADMINS_LIST = '/users/admins',
  USERS_INTERPRETERS_LIST = '/users/interpreters',
  USER_EDIT = "/users/edit/:id",

  COMMENTS_PAGE = "/comments",

  COMMISSIONS_LIST = "/commissions",
  COMMISSION_EDIT = "/commissions/:id",

  SUMMARIES_LIST = "/summaries",
  SUMMARY_EDIT = "/summaries/:id",

  TAGS_LIST = "/tags",
  TAG_EDIT = "/tags/:id",


  RESERVED_DATES_PAGE = "/reserveddates",
  ORDERS_LIST = "/orders",
  ORDER_EDIT = "/orders/:id",

  TASKS_LIST = "/tasks",

  LANGUAGE_CODES_LIST = '/languagecodes',
  LANGUAGE_CODE_EDIT = '/languagecodes/:id',

  LANGUAGES_LIST = '/languages',
  LANGUAGE_EDIT = '/languages/:id',
  WORKING_HOURS_LIST_PAGE = "/workinghours/list",
  WORKING_HOURS_EDIT_PAGE = "/workinghours/:userId/:date",
  WORKING_HOURS_SUMMARIES_LIST_PAGE = "/workinghours/summaries",

  USER_GROUPS_LIST = '/usergroups',
  USER_GROUPS_EDIT = '/usergroups/:id',

  CATEGORIES_LIST = '/categories',
  CATEGORIES_EDIT = '/categories/:id',

  ATTRIBUTES_LIST = '/attributes',
  ATTRIBUTES_EDIT = '/attributes/:id',

  RESOURCES_LIST = '/resources',
  RESOURCES_EDIT = '/resources/:id',

  EMAIL_ORDERS_LIST = '/emailorders',
  EMAIL_ORDERS_EDIT = '/emailorders/:id',

  USER_CONTRACT_SETTINGS_LIST = '/usercontractsettings',
  USER_CONTRACT_SETTINGS_EDIT = '/usercontractsettings/:id',

  USER_SHIFTS_LIST = '/usershifts',
  USER_SHIFTS_EDIT = '/usershifts/:id',

  EEZY_TRUSTER_EVENTS = '/eezytrusterevents',
  EEZY_TRUSTER_EVENT = '/eezytrusterevents/:id',

  APPLY_PAGE = '/apply/:prefix',
  LEADS_LIST = "/leads",
  LEADS_EDIT = "/leads/:id",
  PAGE_NOT_FOUND = "/page-not-found",
}

/**
 * ENUM for unauthenticated users. Separated from the rest so we have a nice and clean enum for this.
 * Lateron, pages, access etc. should be implemented to console
 */
export enum ERouteUnAuthAllow {
	USER_LOGIN = "/login",
	ORDER_MOD_PAGE='/modifyorder',
	APPLY_PAGE = '/apply/:prefix',
}

export enum ERouteStaticParams {
  add = 'add'
}

interface IParams {
  [key: string]: string
}

export class Routes {

  public static withParams(route: ERoute, params: IParams): ERoute {
    let routeWithParams: string = route;
    for(let key in params) {
      routeWithParams = routeWithParams.replace(`:${key}`, params[key]);
    }
    return routeWithParams as ERoute;
  }
}