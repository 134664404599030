import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import { IOrder, getOrders, getOrdersClear } from "../store/actions/ordersActions";

export const PAGINATION_FETCH_SIZE = 20;

export interface IEezyTrusterFetchOptions {
	start: number;
	fetchSize: number;
}

export const OrdersPagePagination = function () {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);

	const [orders, setOrders] = useState<IOrder[] | null>(null);

	const [loadingCheck, setLoadingCheck] = useState(false);

	const {useOrders, error, loading, hasMoreOrders } = useSelector(
		(state: IAppState) => ({
			useOrders: state.orders.orders,
			error: state.orders.error,
			loading: state.orders.ordersLoading,
			hasMoreOrders: state.orders.hasMoreOrders
		})
	);

	useEffect(() => {
		setOrders(useOrders);
		setLoadingCheck(false);
	}, [useOrders]);

	const clearOrders = useCallback(() => {
		dispatch(getOrdersClear());
	}, [dispatch]);

	const searchHandler = useCallback((start: number) => {
		dispatch(getOrders(start));
	}, [dispatch]);

	const handleObserver = useCallback((entries) => {
		if (!hasMoreOrders || loading || loadingCheck || !useOrders || useOrders?.length === 0) return;
		setLoadingCheck(true);
		const target = entries[0];
		if (target.isIntersecting) {
			searchHandler(useOrders.length);
		} else {
			setLoadingCheck(false);
		}
	}, [loading, useOrders, searchHandler, hasMoreOrders, loadingCheck]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	return { loaderRef, events: orders, loading, searchHandler, hasMoreOrders, error, orders, clearOrders };
};
