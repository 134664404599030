import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Heading from "../../components/Heading/Heading";

import Container from "../../components/ui/Container/Container";
import { ETranslation } from "../../translations/translation-keys";
import SummariesList from "../../components/Summary/SummariesList";
import { Routes, ERoute } from "../../classes/Routes";
import { RouteComponentProps } from "react-router-dom";
import { SummariesPagination } from "../../hooks/useSummaries";
import Spinner from "../../components/ui/Spinner/Spinner";
import { ISummarySearch } from "../../store/actions/summariesActions";
import SummarySearch from "../../components/Summary/SummariesSearch/SummarySearch";

interface IProps extends RouteComponentProps { }

const SummariesPage: React.FC<IProps> = ({ history }) => {
	const { t } = useTranslation();

	const { summaries, error, loading, searchHandler, loaderRef, hasNext, clearSummaries} = SummariesPagination();

	useEffect(() => {
		searchHandler({page: 1});
		return(() => {
			clearSummaries();
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const searchHandlerCallBack = (search: ISummarySearch) =>{
		clearSummaries();
		searchHandler(search);
	}

	const openHandler = (id: string) => {
		history.push(Routes.withParams(ERoute.SUMMARY_EDIT, { id }));
	};

	const loaderEl = <p ref={loaderRef} style={{ color: hasNext ? "#ccc" : "#fff" }}>{hasNext ? "Näytä lisää rivejä..." : ""}</p>;

	return (
		<Container>
			<Heading>{t(ETranslation.MENU_SUMMARIES)}</Heading>{" "}
			<SummarySearch searchHandler={searchHandlerCallBack} />
			<hr />
			<SummariesList
				summaries={summaries}
				error={error}
				openSummary={openHandler}
			/>
			{loading ? <Spinner /> : loaderEl}
		</Container>
	);
};
export default SummariesPage;
