import { faFileAlt, faPaperclip, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { EMessageContentType, Message } from '../../../classes/Message';
import { ERoute, ERouteStaticParams, Routes } from '../../../classes/Routes';
import { ETranslation } from '../../../translations/translation-keys';
import Button, { EButtonSize } from '../../ui/Button/Button';
import LabelText from '../../ui/LabelText/LabelText';
import MessageList from '../MessageList/MessageList';
import classes from './MessageListItem.module.scss';

interface IProps extends RouteComponentProps {
  message: Message;
}

interface IIconProps {
  contentType: EMessageContentType;
}

interface ITitleProps {
  message: Message;
}

const MessageListItem: React.FC<IProps> = ({ message, history }) => {
  const { t } = useTranslation();
  const Icon: React.FC<IIconProps> = ({ contentType }) => {
    return (
      <span className={classes.Icon}>
        {(() => {
          switch (contentType) {
            case EMessageContentType.AUDIO:
              return <FontAwesomeIcon icon={faVolumeUp} />;
            case EMessageContentType.FILE:
              return <FontAwesomeIcon icon={faPaperclip} />;
            case EMessageContentType.TEXT:
              return <FontAwesomeIcon icon={faFileAlt} />;
          }
        })()}
      </span>
    );
  };

  const Title: React.FC<ITitleProps> = ({ message }) => {
	const { t } = useTranslation();
    return <span className={classes.Title}>{`${message.getTypesText(t)}${message.getContentTypeText(t)}`} {message.isSource() && `(${message.getTypesString(t)})`}</span>;
  };

  const openHandler = (id: string) => {
    history.push(Routes.withParams(ERoute.MESSAGE_SHOW, { id }));
  };

  const answerHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    event.stopPropagation();
    history.push(Routes.withParams(ERoute.MESSAGE_ANSWER, {
      id: ERouteStaticParams.add,
      parentMessageId: id
    }));
  };

  const hasButtons = (message: Message) => {
    return message.canAnswer() || message.canEdit();
  };

  const editHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string, messageGroupId: string) => {
    event.stopPropagation();
    history.push(Routes.withParams(ERoute.MESSAGE_EDIT, {
      id,
      messageGroupId
    }));
  }

  return (
    <>
      <li className={classes.Container} onClick={() => openHandler(message.id)}>
        <span className={classes.Indicator}></span>
        <div className={classes.Content}>
          <p>
            <Icon contentType={message.contentType} />
            <Title message={message}/>
          </p>
          <p>
            <b>{message.user?.name}</b>
          </p>
          {message.textContentShort && (
            <LabelText label={t(ETranslation.MESSAGE_CONTENT)}>
              {message.textContentShort}
            </LabelText>
          )}
          {message.info && (
            <LabelText label={t(ETranslation.COMMON_ADDITIONAL_INFO)}>{message.info}</LabelText>
          )}
          {hasButtons(message) && (
            <div className={classes.Buttons}>
              {message.canAnswer() && (
                <Button
                  size={EButtonSize.SMALL}
                  onClick={(event) => answerHandler(event, message.id)}
                >
                  Vastaa
                </Button>
              )}
              {message.canEdit() && (
                <Button
                size={EButtonSize.SMALL}
                onClick={(event) => editHandler(event, message.id, message.parentMessageGroupId)}
              >
                Muokkaa
              </Button>
              )}
            </div>
          )}
        </div>
      </li>
      {message.messages && <MessageList messages={message.messages} />}
    </>
  );
};

export default withRouter(MessageListItem);
