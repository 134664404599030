import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { EButtonColor } from '../../../components/ui/Button/Button';
import {  IInputField } from '../../../components/ui/Input/Input';
import { getInputData, initForm } from '../../../components/ui/Input/input-utils';
import InputGroup from '../../../components/ui/InputGroup/InputGroup';
import { useCreateInput } from '../../../hooks/useCreateInput';
import { searchOrganizations } from '../../../services/searchServices';
import { getUserOptions, searchInterpreters } from '../../../services/userServices';
import { getSummariesClear, ISummarySearch } from '../../../store/actions/summariesActions';
import { ETranslation } from '../../../translations/translation-keys';
import { useStoredSearchParams } from '../../../hooks/useStoredSearchParams';
import ISummaryAction from '../../../interfaces/store/ISummaryAction';
import { useDispatch } from 'react-redux';
import { useInitSearchValues } from '../../../hooks/useInitSearchValues';
import { getOrganizationOptions } from '../../../services/organizationServices';
import { EInputs, initialInputs } from './SummarySearchInputs';
import { EStorageKeys } from '../../../shared/storage-keys';

interface IProps {
	searchHandler: (search: ISummarySearch) => void
}

const SummarySearch: React.FC<IProps> = ({ searchHandler }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const [inputs, setInputs] = useState<IInputField>(initialInputs);

	const { setId: setOrganizationId, isLoading: isLoadingOrganizations } = useInitSearchValues(setInputs, EInputs.organization, getOrganizationOptions);
	const { setId: setUserId, isLoading: isLoadingUsers } = useInitSearchValues(setInputs, EInputs.user, getUserOptions);

	const { onSearch, clearInputs } = useStoredSearchParams<ISummarySearch, ISummaryAction, ISummarySearch>({
		setInputs,
		initialInputs,
		storageKey: EStorageKeys.SUMMARIES_SEARCH_PARAMS,
		clearResults: useCallback(() => dispatch(getSummariesClear()), [dispatch]),
		searchHandlerDataTransform: useCallback((data: ISummarySearch) => {
			return { ...data, page: 1 }
		}, []),
		searchHandler,
		initIdInputs: useCallback((data: ISummarySearch) => {
			initForm(setInputs, { ...data, user: '', organization: [] });
			if (data.organization && data.organization.length > 0) setOrganizationId(data.organization)
			if (data.user && data.user.length > 0) setUserId(data.user)
		}, [setUserId, setOrganizationId])
	})

	const createInput = useCreateInput(inputs, setInputs);

	return (
		<>
			<InputGroup>
				{createInput(EInputs.startDate)}
				{createInput(EInputs.endDate)}
			</InputGroup>
			{createInput(EInputs.status)}
			{createInput(EInputs.user, {
				fetchOptions: searchInterpreters,
				isLoading: isLoadingUsers
			})}
			{createInput(EInputs.organization, {
				fetchOptions: searchOrganizations,
				isLoading: isLoadingOrganizations
			})}
			<InputGroup style={{ margin: 0}} >
				<Button onClick={() => onSearch(getInputData(inputs))}>
					{t(ETranslation.COMMON_SEARCH)}
				</Button>
				<Button color={EButtonColor.DEFAULT} onClick={clearInputs}>{t(ETranslation.UI_CLEAR)}</Button>
			</InputGroup>
		</>
	);
};
export default SummarySearch;
