import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import './MessageGroupEditPage.scss'
import { IMessageGroup } from '../../../classes/MessageGroup';
import { ERoute, ERouteStaticParams, Routes } from '../../../classes/Routes';
import Heading from '../../../components/Heading/Heading';
import MessageGroupEdit from '../../../components/Message/MessageGroupEdit/MessageGroupEdit';
import Alert from '../../../components/ui/Alert/Alert';
import Button from '../../../components/ui/Button/Button';
import Container from '../../../components/ui/Container/Container';
import EditButtons from '../../../components/ui/EditButtons/EditButtons';
import Spinner from '../../../components/ui/Spinner/Spinner';
import { useLanguageName } from '../../../hooks/useLanguageName';
import IAppState from '../../../interfaces/store/IAppState';
import * as actions from '../../../store/actions';
import { ETranslation } from '../../../translations/translation-keys';
import { adminUiUrl } from '../../../config';
import IMessageState from '../../../interfaces/store/IMessageState';
import IInterpretationState from '../../../interfaces/store/IInterpretationState';

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {}

const MessageGroupEditPage: React.FC<IProps> = ({ match, history }) => {
  const [editMessageGroup, setEditMessageGroup] = useState<IMessageGroup | null>(
    null
  );
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const dispatch = useDispatch();

  const { id } = match.params;
  const isAdd = id === "add";
  const { t } = useTranslation();
  useEffect(() => {
    if (!isAdd) {
      dispatch(actions.getMessageGroup(id));
      dispatch(actions.getInterpretationMessageGroup(id))
    }
  }, [isAdd, id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(actions.getInterpretationMessageGroupClear());
    }
  }, [dispatch]);

  const { interpretation } = useSelector<IAppState, IInterpretationState>(state => state.interpretation);
  const { messageGroupError, saveOrUpdateError, messageGroupLoading, saveOrUpdateLoading, saveOrUpdateOk, messageGroup, messageGroupDeleteError, messageGroupDeleteLoading, messageGroupDeleteOk } = useSelector<IAppState, IMessageState>(state => state.message);
  const error = messageGroupError || saveOrUpdateError;
  const loading = messageGroupLoading || saveOrUpdateLoading;



  useEffect(() => {
    if (saveOrUpdateOk) {
      history.push(ERoute.MESSAGE_GROUPS_LIST);
    }
    return () => {
      dispatch(actions.messageSaveOrUpdateClear());
      dispatch(actions.getMessageGroupClear());
      dispatch(actions.deleteMessageGroupClear());
    };
  }, [dispatch, saveOrUpdateOk, history]);

  const updateHandler = useCallback(
    (messageGroup: IMessageGroup, isValid: boolean) => {
      setEditMessageGroup(messageGroup);
      setIsValid(isValid);
    },
    []
  );

  const submitHandler = () => {
    if (!editMessageGroup) return;

    const messageGroupData: any = editMessageGroup;
    if(messageGroupData.organizations) {
      messageGroupData.organizations = messageGroupData.organizations.map((id: any) => ({ id }));
    }

    if (isAdd) {
      dispatch(actions.saveMessageGroup(messageGroupData));
    } else {
      messageGroupData.id = id;
      dispatch(actions.updateMessageGroup(messageGroupData));
    }
  };

  const deleteHandler = () => {
    if(messageGroup) {
      dispatch(actions.deleteMessageGroup(messageGroup.id));
    }
  }

  const messageAddHandler = () => {
    history.push(Routes.withParams(ERoute.MESSAGE_EDIT, { id: ERouteStaticParams.add, messageGroupId: id }));
  }

  const toLanguage = useLanguageName(interpretation?.toLanguage);
  const fromLanguage = useLanguageName(interpretation?.fromLanguage);

  return (
    <Container>
      {error && <Alert>{error}</Alert>}
      {loading  && <Spinner centerAbsolute />}
      <Heading>{t(ETranslation.PAGES_MESSAGE_GROUP_EDIT_TITLE)}</Heading>
	  {interpretation && (
		<>
			<Heading>{t(ETranslation.COMMON_INTERPRETATION_INFORMATION)}</Heading>
			{fromLanguage &&
			<p>{interpretation?.startDate}
			<br/> {t(ETranslation.COMMON_FROM_LANGUAGE)+ ":"+ fromLanguage}
			<br/> {t(ETranslation.COMMON_TO_LANGUAGE)+ ":"+ toLanguage}
			</p>
			}
			{interpretation?.id &&
			<Button
					onClick={() =>
						window.open(
						adminUiUrl + "/interpretations/" +
							interpretation?.id,
						"_blank",
						"noopener, noreferrer"
						)
					}
			>{t(ETranslation.COMMON_LINK)}</Button>
			}
		</>
	  )}

      <MessageGroupEdit
        onChange={updateHandler}
        loading={loading}
        messageGroup={messageGroup}
        showValidation={showValidation}
        onMessageAdd={messageAddHandler}
      />
      <EditButtons
        onSave={isValid ? submitHandler : () => setShowValidation(true)}
        disabled={showValidation && !isValid}
        loading={loading}
        onCancel={() => history.goBack()}
        isAdd={isAdd}
        onDelete={deleteHandler}
        deleteText={ETranslation.PAGES_MESSAGE_GROUP_EDIT_DELETE_CONFIRM}
        onDeleteClose={() => dispatch(actions.deleteMessageGroupClear())}
        onDeleteDone={() => history.push(ERoute.MESSAGE_GROUPS_LIST) }
        deleteError={messageGroupDeleteError}
        deleteLoading={messageGroupDeleteLoading}
        deleteOk={messageGroupDeleteOk}
        />

    </Container>
  );
};

export default MessageGroupEditPage;

/*

*/