import React from "react";
import classes from "./UserComment.module.scss";

interface IProps {
	label: string;
	value: string;
}

const UserCommentItem: React.FC<IProps> = ({ label, value }) => {
	return (
		<div className={classes.Container}>
			<div className={classes.Comment}>{value}</div>
			<div className={classes.Label}>{label}</div>
		</div>
	);
};

export default UserCommentItem;
