import { ILead } from "../../interfaces/ILead";
import EActionTypes from "../../interfaces/store/EActionTypes";
import ILeadsAction from "../../interfaces/store/ILeadsAction";
import ILeadsState from "../../interfaces/store/ILeadsState";

type ReducerState = ILeadsState;
type ReducerSignature = (state: ReducerState, action: ILeadsAction) => ReducerState;


const initialState: ReducerState = {
	leadsLoading: false,
	leadsError: null,
	hasMoreLeads: false,
	leads: null,
	leadLoading: false,
	leadError: null,
	lead: null,
};

const getLeadsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, leadsError: null, leadsLoading: true };
};

const getLeadsSuccess: ReducerSignature = (state, action): ReducerState => {
	let leads: ILead[] |null = null;
	if(!action.leads) {
		leads = state.leads ?? null;
	} else {
		leads = state.leads ? [...state.leads, ...action.leads] : action.leads
	}
	// TODO(Joonas): Nää hakuhommat pitäs normalisoida johonkin yksinkertaisemmin...
	const hasMoreLeads = (action.count && (action.leads && action.leads.length > 0) && leads) ? action.count === leads.length : false;
	return {
		...state,
		leadsError: null,
		leadsLoading: false,
		leads,
		hasMoreLeads
	};
};

const getLeadsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, leadsError: null, leadsLoading: false, leads: null };
};

const getLeadsError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, leadsError: action.error ?? "Jokin meni pieleen", leadsLoading: false, leads: null };
}

const updateLeadsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, leadsError: null, leadsLoading: true };
};
const updateLeadsSuccess: ReducerSignature = (state, action): ReducerState => {
	let leads: ILead[] = (state.leads as (ILead[])).map((lead) =>{
		return action.leads?.find((item)=> item.id === lead.id) ?? lead
	})
	return { ...state, leadsError: null, leadsLoading: false, leads: leads };
};

const updateLeadsError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, leadsError: action.error ?? "Jokin meni pieleen", leadsLoading: false, leads: null };
}

const reducer = (state: ReducerState = initialState, action: ILeadsAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.LEADS_GET_START:
			return getLeadsStart(state, action);
		case EActionTypes.LEADS_GET_SUCCESS:
			return getLeadsSuccess(state, action);
		case EActionTypes.LEADS_GET_CLEAR:
			return getLeadsClear(state, action);
		case EActionTypes.LEADS_GET_ERROR:
			return getLeadsError(state, action);
		case EActionTypes.LEADS_UPDATE_START:
			return updateLeadsStart(state, action);
		case EActionTypes.LEADS_UPDATE_SUCCESS:
			return updateLeadsSuccess(state, action);
		case EActionTypes.LEADS_UPDATE_ERROR:
			return updateLeadsError(state, action);
		default:
			return state;
	}
};

export default reducer;
