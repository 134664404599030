import React from "react";
import { ITag } from "../../store/actions/tagsActions";
import Spinner from "../../components/ui/Spinner/Spinner";
import Table from "../../components/ui/Table/Table";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import Alert from "../ui/Alert/Alert";

interface IProps {
	tags: ITag[] | null;
	loading: boolean;
	error: string | null;
	openTag: (tag: ITag) => void;
}

const TagsList: React.FC<IProps> = ({ tags, loading, error, openTag }) => {
	const { t } = useTranslation();
	return loading ? (
		<Spinner />
	) : error ? (
		<Alert>{error}</Alert>
	) : (
		<Table hover={true}>
			<thead>
				<tr>
					<th>{t(ETranslation.COMMON_NAME)}</th>
					<th>{t(ETranslation.COMMON_DESCRIPTION)}</th>
				</tr>
			</thead>
			<tbody>
				{tags &&
					tags.map((tag, id) => {
						return (
							<tr
								key={id}
								onClick={() => {
									openTag(tag);
								}}
							>
								<td>{tag.name}</td>
								<td>{tag.description}</td>
							</tr>
						);
					})}
			</tbody>
		</Table>
	);
};

export default TagsList;
