import React, { useEffect, useState } from 'react';

interface IProps {
	fileUrl: string;
}

const WordViewer: React.FC<IProps> = ({ fileUrl }) => {
    const [html, setHtml] = useState("");

    useEffect(() => {
        const loadWord = async () => {
            const mammoth = await import("mammoth");
            const response = await fetch(fileUrl);
            const arrayBuffer = await response.arrayBuffer();
            const result = await mammoth.convertToHtml({ arrayBuffer });
            setHtml(result.value);
        };

        loadWord();
    }, [fileUrl]);

    return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

export default WordViewer;