import React, { useEffect, useState } from 'react';

import classes from './ExcelViewer.module.scss';


interface IProps {
	fileUrl: string;
}

const ExcelViewer: React.FC<IProps> = ({ fileUrl }) => {
	const [html, setHtml] = useState("");

    useEffect(() => {
        const loadExcel = async () => {
            const XLSX = await import("xlsx");
            const response = await fetch(fileUrl);
            const arrayBuffer = await response.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const htmlContent = XLSX.utils.sheet_to_html(sheet);
            setHtml(htmlContent);
        };
        loadExcel();
    }, [fileUrl]);
    return <div dangerouslySetInnerHTML={{ __html: html }} className={classes.Container} />;
}

export default ExcelViewer;