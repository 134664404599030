import { ELanguage } from "../i18n";



export const getYoupretLang = () => {
	var cookies = document.cookie.split(";");
	for (var i = 0; i < cookies.length; i++) {
		const equals = cookies[i].indexOf("=");
		let name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
		name = name.trim();
		let value = equals >= -1 ? cookies[i].substring(equals + 1) : cookies[i];
		if (name === "youpretLang" && Object.values(ELanguage).includes(value as ELanguage)) {
			return value.trim();
		}
	}
	return null;
}