import React, { useCallback, useContext } from 'react';

import ModalContext, { EModalSize } from '../ui/Modal/ModalContext';
import FilePreview, { isExcel } from './FilePreview';


export const useFilePreviewModal = () => {
  const { setModal } = useContext(ModalContext);

  const handler = useCallback(async (fileUrl: string, contentType: string) => {
      setModal({
        isOpen: true,
        size: isExcel(contentType) ? EModalSize.FULL : EModalSize.MEDIUM,
        title: "",
        content: <FilePreview  fileUrl={fileUrl} contentType={contentType} />,
      });
  }, [setModal ]);

  return handler;
};
