import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import * as actions from "../store/actions";
import { IOrganization } from "../interfaces/IOrganization";
import { IOption } from "../components/ui/Input/Input";

export interface IPaginationOptions {
	page: number;
	id?: string;
	type?: EOrganizationTypes[],
	organizationGroup?: string;
}

export enum EOrganizationTypes {
	CUSTOMER = "CUSTOMER",
	INTERPRETER = "INTERPRETER",
	UNKNOWN = "UNKNOWN",
}

export const organizationTypes: IOption[] = [
	{ value: EOrganizationTypes.CUSTOMER, label: EOrganizationTypes.CUSTOMER.toLowerCase() },
	{ value: EOrganizationTypes.INTERPRETER, label: EOrganizationTypes.INTERPRETER.toLowerCase() },
	{ value: EOrganizationTypes.UNKNOWN, label: EOrganizationTypes.UNKNOWN.toLowerCase() },
]

export const useOrganizationPagination = function () {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);

	const [searchOptions, setSearchOptions] = useState<IPaginationOptions | null>(null);
	const [organizations, setOrganizations] = useState<IOrganization[] | null>(null);

	const [loadingCheck, setLoadingCheck] = useState(false);

	const {
		organizations: stateOrganizations,
		organizationsLoading,
		organizationsError,
		organizationsHasMore
	} = useSelector((state: IAppState) => state.organization);

	useEffect(() => {
		setOrganizations(stateOrganizations);
		setLoadingCheck(false);
	}, [stateOrganizations]);

	const searchHandler = useCallback((search: IPaginationOptions) => {
		dispatch(actions.findOrganizations(search));
		setSearchOptions(search);
	}, [dispatch]);

	const handleObserver = useCallback((entries) => {
		if (!organizationsHasMore || !searchOptions || organizationsLoading || loadingCheck || !stateOrganizations || stateOrganizations?.length === 0) return;
		setLoadingCheck(true);
		const target = entries[0];
		if (target.isIntersecting) {
			searchHandler({
				page: searchOptions.page + 1,
			});
		} else {
			setLoadingCheck(false);
		}
	}, [organizationsLoading, stateOrganizations, searchHandler, searchOptions, organizationsHasMore, loadingCheck]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	return { loaderRef, organizations, organizationsLoading, searchHandler, organizationsHasMore, organizationsError };
};
