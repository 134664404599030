import React, { useCallback, useEffect, useState } from "react";

import { useCreateInput } from "../../hooks/useCreateInput";
import { IAttribute } from "../../interfaces/IAttribute";
import { ETranslation } from "../../translations/translation-keys";
import { EInputType, IInputField } from "../ui/Input/Input";
import { getInputData, initForm, validateInputs } from "../ui/Input/input-utils";
import Translations from "../Translations/Translations";
import { useTranslations } from "../Translations/useTranslations";
import { useCategoryOptions } from "../../hooks/useCategoryOptions";
import { searchOrganizations } from "../../services/searchServices";
import { getOrganizationOptions } from "../../services/organizationServices";
import { useInitSearchValues } from "../../hooks/useInitSearchValues";
import { useOrganizationGroupOptions } from "../../hooks/useOrganizationGroupOptions";
import { IAttributeSettings } from "../../interfaces/IAttributeSettings";
import AttributeSettings from "./AttributeSettings";

enum EInputs {
	name = "name",
	categoryId = "categoryId",
	organizationIds = "organizationIds",
	organizationGroupIds = "organizationGroupIds",
	isVisible = "isVisible",
}

interface IProps {
	onChange: (attribute: IAttribute, isValid: boolean) => void;
	attribute: IAttribute | null;
	loading?: boolean;
	showValidation?: boolean;
}

const AttributeEdit: React.FC<IProps> = ({ onChange, attribute, loading, showValidation }) => {
	const [attributeSettings, setSettings] = useState<IAttributeSettings>();
	const { translations, initTranslations, onSaveTranslation, onDeleteTranslation } = useTranslations();
	const { categoryOptions, categoriesLoading } = useCategoryOptions({ showSettings: true });
	const { organizationGroupOptions, organizationGroupsLoading } = useOrganizationGroupOptions();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.categoryId]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_CATEGORY,
			value: "",
			isClearable: true,
			validation: {
				required: false,
			},
		},
		[EInputs.organizationIds]: {
			type: EInputType.reactSelectSearch,
			labelTranslation: ETranslation.COMMON_ORGANIZATIONS,
			value: [],
			multiple: true,
		},
		[EInputs.organizationGroupIds]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_ORGANIZATION_GROUP,
			value: [],
			multiple: true,
		},
		[EInputs.isVisible]: {
			type: EInputType.boolean,
			labelTranslation: ETranslation.COMMON_SHOW,
			value: false,
		},
	});

	const { setIds: setOrganizationIds, isLoading: isLoadingOrganizations } = useInitSearchValues(setInputs, EInputs.organizationIds, getOrganizationOptions);

	useEffect(() => {
		if (attribute) {
			initForm(setInputs, attribute);
			initTranslations(attribute.translations);
			setOrganizationIds(attribute.organizationIds);
		}
		// eslint-disable-next-line
	}, [attribute, initTranslations]);

	useEffect(() => {
		const attribute = getInputData<IAttribute>(inputs);
		const isValid = validateInputs(inputs);
		attribute.translations = translations;
		attribute.settings = attributeSettings;
		onChange(attribute, isValid);
	}, [inputs, onChange, translations, attributeSettings]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	const settingsChangeHandler = useCallback((settings: IAttributeSettings, isValid: boolean) => {
		setSettings(settings);
	}, []);

	return (
		<div>
			{createInput(EInputs.name)}
			{createInput(EInputs.categoryId, {
				options: categoryOptions,
				isLoading: categoriesLoading,
			})}
			{createInput(EInputs.organizationIds, {
				fetchOptions: searchOrganizations,
				isLoading: isLoadingOrganizations
			})}
			{createInput(EInputs.organizationGroupIds, {
				options: organizationGroupOptions,
				isLoading: organizationGroupsLoading
			})}
			{createInput(EInputs.isVisible)}
			<AttributeSettings
				onChange={settingsChangeHandler}
				loading={loading}
				settings={attribute?.settings ?? null}
			/>
			<Translations
				translations={translations}
				onDelete={(_, langCode) => onDeleteTranslation(langCode)}
				onSave={onSaveTranslation}
				isLoading={false}
				error={null}
			/>
		</div>
	);
};

export default AttributeEdit;
