import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '../../../components/ui/Container/Container';
import Heading from '../../../components/Heading/Heading';
import { ETranslation } from '../../../translations/translation-keys';
import { LeadsListPagination, PAGINATION_FETCH_SIZE } from '../../../hooks/useLeadsList';
import { getLeadsClear } from '../../../store/actions/leadsActions';
import Alert, { EAlertColor } from '../../../components/ui/Alert/Alert';
import Spinner from '../../../components/ui/Spinner/Spinner';
import LeadsListContent from '../../../components/Leads/LeadsList/LeadsListContent';
import { ILead } from '../../../interfaces/ILead';
import { createListSelectContext } from '../../../hooks/useListSelectContext';
import LeadsSendMailButton from '../../../components/Leads/LeadsList/LeadsSendMailButton';


interface IProps extends RouteComponentProps { }
export const { SelectedItemsContext: SelectedLeadsContext } = createListSelectContext<ILead>();

const LeadsListPage: React.FC<IProps> = ({ history }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { searchHandler, leads, leadsLoading, loaderRef, error, hasMoreLeads } = LeadsListPagination()

	useEffect(() => {
		searchHandler({ start: 0, fetchSize: PAGINATION_FETCH_SIZE })
		return () => {
			dispatch(getLeadsClear())
		}
	}, [dispatch, searchHandler])


	const [selectedRows, setSelectedRows] = useState<ILead[]>([]);
	const [selectAll, setSelectAll] = useState(false);

	return (
		<Container>
			<SelectedLeadsContext.Provider value={{ selectedRows, setSelectedRows, selectAll, setSelectAll }} >
				<Heading>
					{t(ETranslation.MENU_LEADS)}
					{(selectedRows.length > 0 && leads) && <LeadsSendMailButton leads={selectedRows} />}
				</Heading>
				{error && <Alert color={EAlertColor.WARNING}>{error}</Alert>}
				{leads && <LeadsListContent leads={leads} onRowClick={(lead) => { console.log(lead); }} />}
				{leadsLoading && <Spinner />}
				<p
					ref={loaderRef}
					style={{ color: hasMoreLeads ? "#ccc" : "#fff" }}
				>
					{hasMoreLeads ? "Näytä lisää rivejä..." : ""}
				</p>
			</SelectedLeadsContext.Provider>
		</Container>
	);
};

export default LeadsListPage;
