import { Dispatch } from "redux";
import EActionTypes from "../../interfaces/store/EActionTypes";
import IOrderAction from "../../interfaces/store/IOrderAction";
import { EFetchMethod, customFetch } from "../../custom-fetch";
import { IUser } from "../../classes/User";
import { EPaymentStatus } from "../../shared/order-data";
import { PAGINATION_FETCH_SIZE } from "../../hooks/useOrders";
import { EInterpretationType } from "../../shared/interpretation-data";

export interface IOrder {
	created: string;
	createdDateTime: string;
	customerEmail: string;
	customerFirstName: string;
	customerLastName: string;
	customerNotes: string;
	customerOrganizationBusinessId?: string;
	customerOrganizationName: string;
	customerPhoneNumber: string;
	durationInMinutes: number;
	fromLanguage: string;
	fromLanguageText?: string;
	id: string;
	site: string;
	startDate: string;
	startDateUTC: string;
	startTime: string;
	status: IOrderStatus;
	toLanguage: string;
	toLanguageText: string;
	type: EInterpretationType;
	tzString: string;
	updated: string;
	updatedDateTime: string;
	uri?: string;
	user?: IUser;
	paymentStatus: EPaymentStatus;
	paymentId: string;
	address: string;
	zip: string;
	city: string;
	info: string;
}

export enum IOrderStatus {
	NEW = "NEW",
	OPEN = "OPEN",
	REJECTED = "REJECTED",
	COMPLETED = "COMPLETED",
}

type TAction = IOrderAction;


const getOrdersStart = (): TAction => {
	return {
		type: EActionTypes.ORDERS_GET_START
	};
};

const getOrdersSuccess = (orders: IOrder[]): TAction => {
	return {
		type: EActionTypes.ORDERS_GET_SUCCESS,
		orders
	};
};

const getOrdersError = (error: string): TAction => {
	return {
		type: EActionTypes.ORDERS_GET_ERROR,
		error
	};
};

export const getOrdersClear = (): TAction => {
	return {
		type: EActionTypes.ORDERS_GET_CLEAR,
	};
};

export const getOrders = (start: number) => {
	return async (dispatch: Dispatch) => {
		dispatch(getOrdersStart())
		try {
			customFetch<{ data: IOrder[] }>("/orders", EFetchMethod.POST, JSON.stringify({ fetchSize: PAGINATION_FETCH_SIZE, start: start })).then((data) => {
				dispatch(getOrdersSuccess(data.data));
			}).catch((error) =>{
				dispatch(getOrdersError((error as Error).message));
			})
		} catch (error) {
			dispatch(getOrdersError((error as Error).message));
		}
	};
};

const getOrderStart = (): TAction => {
	return {
		type: EActionTypes.ORDER_GET_START
	};
};

const getOrderSuccess = (order: IOrder): TAction => {
	return {
		type: EActionTypes.ORDER_GET_SUCCESS,
		order
	};
};

export const saveOrUpdateOrderClear = (): TAction => {
	return {
		type: EActionTypes.ORDER_SAVE_CLEAR
	}
}

const saveOrUpdateOrderError = (error: string): TAction => {
	return {
		type: EActionTypes.ORDER_SAVE_ERROR,
		saveOrUpdateError: error
	}
}

const saveOrUpdateOrderDone = (): TAction =>{
	return {
		type: EActionTypes.ORDER_SAVE_SUCCESS
	}
}

export const getOrderClear = (): TAction =>{
	return {
		type: EActionTypes.ORDER_GET_CLEAR
	}
}

const saveOrUpdateOrderStart = (): TAction =>{
	return {
		type: EActionTypes.ORDER_SAVE_START
	}
}

export const saveOrder = (order: IOrder) => {
	return async (dispatch: Dispatch) => {
		dispatch(saveOrUpdateOrderStart())
		try {
			customFetch("/orders/add", EFetchMethod.POST, JSON.stringify(order)).then(() => {
				dispatch(saveOrUpdateOrderDone());
			}).catch((error) =>{
				dispatch(getOrdersError((error as Error).message));
			})
		} catch (error) {
			dispatch(saveOrUpdateOrderError((error as Error).message));
		}
	};
}

export const getOrder = (orderId: string) => {
	return async (dispatch: Dispatch) => {
		dispatch(getOrderStart())
		try {
			customFetch<IOrder>("/orders/get?id=" + orderId, EFetchMethod.GET).then((data) => {
				dispatch(getOrderSuccess(data));
			}).catch((error) =>{
				dispatch(getOrdersError((error as Error).message));
			})
		} catch (error) {
			dispatch(getOrdersError((error as Error).message));
		}
	};
};

const exportToYoupretPayStart = (): TAction => {
	return {
		type: EActionTypes.ORDER_EXPORT_TO_YOUPRET_PAY_START,
	};
};

const exportToYoupretPaySuccess = (): TAction => {
	return {
		type: EActionTypes.ORDER_EXPORT_TO_YOUPRET_PAY_SUCCESS,
	};
};

const exportToYoupretPayFail = (error: string): TAction => {
	return {
		type: EActionTypes.ORDER_EXPORT_TO_YOUPRET_PAY_FAIL,
		error,
	};
};

export const exportToYoupretPay = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(exportToYoupretPayStart());
			customFetch<string>(
				`/orders/exporttoyoupretpay?id=${id}`,
				EFetchMethod.POST
			);
			await new Promise(resolve => setTimeout(resolve, 30000));
			dispatch(exportToYoupretPaySuccess());
		} catch (error) {
			dispatch(exportToYoupretPayFail((error as Error).message));
		}
	};
};

const updatePaymentStatusStart = (): TAction => {
	return {
		type: EActionTypes.ORDER_UPDATE_PAYMENT_STATUS_START,
	};
};

const updatePaymentStatusSuccess = (paymentStatus: EPaymentStatus): TAction => {
	return {
		type: EActionTypes.ORDER_UPDATE_PAYMENT_STATUS_SUCCESS,
		paymentStatus,
	};
};

const updatePaymentStatusFail = (error: string): TAction => {
	return {
		type: EActionTypes.ORDER_UPDATE_PAYMENT_STATUS_FAIL,
		error,
	};
};

export const updatePaymentStatus = (id: string) => {
	return async (dispatch: Dispatch) => {
		try {
			dispatch(updatePaymentStatusStart());
			const paymentStatus = await customFetch<EPaymentStatus>(
				`/orders/updatepaymentstatus?id=${id}`,
				EFetchMethod.POST
			);
			dispatch(updatePaymentStatusSuccess(paymentStatus));
		} catch (error) {
			dispatch(updatePaymentStatusFail((error as Error).message));
		}
	};
};
