import React, { useEffect, useState } from "react";

import { useCreateInput } from "../../../hooks/useCreateInput";
import { ETranslation } from "../../../translations/translation-keys";
import { EInputType, IInputField } from "../../ui/Input/Input";
import { getInputData, initForm, validateInputs } from "../../ui/Input/input-utils";
import { IUserContractSettings } from "../../../interfaces/IUserContractSettings";
import InputGroup from "../../ui/InputGroup/InputGroup";
import {
	USER_CONTRACT_HOLIDAY_ACCURUAL_OPTIONS,
	USER_CONTRACT_SETTINGS_TYPES,
} from "../../../shared/user-contract-settings-data";

enum EInputs {
	name = "name",
	description = "description",
	contractStartDate = "contractStartDate",
	contractEndDate = "contractEndDate",
	type = "type",
	trialStartDate = "trialStartDate",
	trialEndDate = "trialEndDate",
	weeklyHoursStart = "weeklyHoursStart",
	weeklyHoursEnd = "weeklyHoursEnd",
	shiftHoursMin = "shiftHoursMin",
	shiftHoursMax = "shiftHoursMax",
	holidayAccrual = "holidayAccrual",
	breakMinutes = "breakMinutes",
}

interface IProps {
	onChange: (settings: IUserContractSettings, isValid: boolean) => void;
	settings: IUserContractSettings | null;
	loading?: boolean;
	showValidation?: boolean;
}

const UserContractSettingsEdit: React.FC<IProps> = ({ onChange, settings, loading, showValidation }) => {
	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: "",
		},
		[EInputs.contractStartDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_START_DATE,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.contractEndDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.COMMON_END_DATE,
			value: "",
		},
		[EInputs.type]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_TYPE,
			value: "",
			options: USER_CONTRACT_SETTINGS_TYPES,
			validation: {
				required: true,
			},
		},
		[EInputs.trialStartDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.USER_CONTRACT_SETTINGS_TRIAL_START_DATE,
			value: "",
		},
		[EInputs.trialEndDate]: {
			type: EInputType.date,
			labelTranslation: ETranslation.USER_CONTRACT_SETTINGS_TRIAL_END_DATE,
			value: "",
		},
		[EInputs.weeklyHoursStart]: {
			type: EInputType.number,
			labelTranslation: ETranslation.USER_CONTRACT_SETTINGS_TRIAL_WEEKLY_HOURS_START,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.weeklyHoursEnd]: {
			type: EInputType.number,
			labelTranslation: ETranslation.USER_CONTRACT_SETTINGS_TRIAL_WEEKLY_HOURS_END,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.shiftHoursMin]: {
			type: EInputType.number,
			labelTranslation: ETranslation.USER_CONTRACT_SETTINGS_WORK_LENGTH_MIN,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.shiftHoursMax]: {
			type: EInputType.number,
			labelTranslation: ETranslation.USER_CONTRACT_SETTINGS_WORK_LENGTH_MAX,
			value: "",
			validation: {
				required: true,
			},
		},
		[EInputs.holidayAccrual]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.USER_CONTRACT_SETTINGS_HOLIDAY_ACCRUAL,
			value: "",
			options: USER_CONTRACT_HOLIDAY_ACCURUAL_OPTIONS,
			isClearable: true,
		},
		[EInputs.breakMinutes]: {
			type: EInputType.number,
			labelTranslation: ETranslation.USER_CONTRACT_SETTINGS_BREAK_MINUTES,
			value: "",
		},
	});

	useEffect(() => {
		if (settings) {
			initForm(setInputs, settings);
		}
		// eslint-disable-next-line
	}, [settings]);

	useEffect(() => {
		const userGroup = getInputData<IUserContractSettings>(inputs);
		const isValid = validateInputs(inputs);
		onChange(userGroup, isValid);
	}, [inputs, onChange]);

	const createInput = useCreateInput(inputs, setInputs, {
		showValidation,
		disabled: loading,
	});

	return (
		<div>
			{createInput(EInputs.name)}
			{createInput(EInputs.description)}
			<InputGroup>
				{createInput(EInputs.contractStartDate)}
				{createInput(EInputs.contractEndDate)}
			</InputGroup>
			{createInput(EInputs.type)}
			<InputGroup>
				{createInput(EInputs.trialStartDate)}
				{createInput(EInputs.trialEndDate)}
			</InputGroup>
			<InputGroup>
				{createInput(EInputs.weeklyHoursStart)}
				{createInput(EInputs.weeklyHoursEnd)}
				{createInput(EInputs.shiftHoursMin)}
				{createInput(EInputs.shiftHoursMax)}
			</InputGroup>
			<InputGroup>
				{createInput(EInputs.holidayAccrual)}
				{createInput(EInputs.breakMinutes)}
			</InputGroup>
		</div>
	);
};

export default UserContractSettingsEdit;
