import { IInterpretation } from "./../interfaces/IInterpretation";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import * as actions from "../store/actions";
import { EInterpreterFeeType } from "../shared/interpretation-data";

export interface IPaginationOptions {
	startDate?: string;
	endDate?: string;
	durationInMinutes?: string;
	type?: string[];
	user?: string[];
	users?: string[];
	status?: string[];
	fetchSize?: number;
	organization?: string;
	organizationGroup?: string;
	fromLanguage?: string;
	toLanguage?: string;
	paginationCursor?: string;
	searchAll?: boolean;
	start?: number;
	fetchFromUserRows?: boolean;
	dateAccuracy?: string;
	startDateEnd?: string;
	startDateStart?: string;
	orderBy?: string;
	exportTypes?: string[];
	feeType?: EInterpreterFeeType;
	customerReference?: string;
}

export const PAGINATION_FETCH_SIZE = 50;

export const usePagination = function () {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);
	const abortController = useRef<null | AbortController>();

	const [searchOptions, setSearchOptions] = useState<IPaginationOptions | null>(null);
	const [interpretations, setInterpretations] = useState<IInterpretation[] | null>(null);

	const [localLoading, setLocalLoading] = useState(false);

	const {
		interpretations: stateInterpretations,
		paginationCursor,
		hasMoreInterpretations,
		averageResponseTime,
		interpretationsLoading: loading,
	} = useSelector((state: IAppState) => state.interpretation);

	useEffect(() => {
		setInterpretations(stateInterpretations);
		setLocalLoading(false);
	}, [stateInterpretations]);

	const searchHandler = useCallback((search: IPaginationOptions) => {
		if (abortController.current) abortController.current.abort();
		abortController.current = new AbortController();
		dispatch(actions.findInterpretations(search, abortController.current.signal));
		setSearchOptions(search);
	}, [dispatch]);

	const [averageReponseTimeCalc, setAverageResponseTimeCalc] = useState<string>();
	const handleObserver = useCallback((entries) => {
		if (!hasMoreInterpretations || !searchOptions || loading || stateInterpretations?.length === 0 || localLoading) { return };
		const timeoutId = setTimeout(() => {
			const target = entries[0];
			if (target.isIntersecting) {
				const start = (searchOptions.start ?? 0) + (searchOptions.fetchSize ?? 0);
				setLocalLoading(true);
				searchHandler({
					...searchOptions,
					start: start,
					searchAll: false,
					paginationCursor: paginationCursor ?? "",
				});
			}
		}, 500);
		return () => clearTimeout(timeoutId);
	}, [loading, stateInterpretations, searchHandler, searchOptions, hasMoreInterpretations, paginationCursor, localLoading]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	useEffect(() => {
		if (averageResponseTime !== null) {
			setAverageResponseTimeCalc(averageResponseTime)
		}
	}, [averageResponseTime])

	const getDurationAverage = useCallback(() => {
		let average = 0;
		let amt = 0;
		if (interpretations?.length) {
			for (let i = 0; i < interpretations.length; i++) {
				if(interpretations[i].durationInMinutes > 0){
					amt++;
					average += +interpretations[i].durationInMinutes;
				}
			}
			if(average > 0){
				average /= amt;
			}
		}
		return average.toFixed();
	}, [interpretations]);

	return { loaderRef, interpretations, loading, searchHandler, hasMoreInterpretations, getDurationAverage, averageReponseTimeCalc };
};
