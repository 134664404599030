import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import * as actions from "../store/actions";
import { IEezyTrusterEvent } from "../interfaces/IEezyTruster";


export const PAGINATION_FETCH_SIZE = 20;

export interface IEezyTrusterFetchOptions {
	start: number;
	fetchSize: number;
}

export const EezyTrusterPagination = function () {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);

	const [events, setEvents] = useState<IEezyTrusterEvent[] | null>(null);

	const [loadingCheck, setLoadingCheck] = useState(false);

	const {
		events: stateEvents,
		eventsLoading,
		error,
		hasMoreEvents,
	} = useSelector((state: IAppState) => state.eezyTruster);

	useEffect(() => {
		setEvents(stateEvents);
		setLoadingCheck(false);
	}, [stateEvents]);

	const searchHandler = useCallback((data: IEezyTrusterFetchOptions) => {
		dispatch(actions.getEezyTrusterEvents(data));
	}, [dispatch]);

	const handleObserver = useCallback((entries) => {
		if (!hasMoreEvents || eventsLoading || loadingCheck || !stateEvents || stateEvents?.length === 0) return;
		setLoadingCheck(true);
		const target = entries[0];
		if (target.isIntersecting) {
			searchHandler({
				start: stateEvents.length,
				fetchSize: PAGINATION_FETCH_SIZE,
			});
		} else {
			setLoadingCheck(false);
		}
	}, [eventsLoading, stateEvents, searchHandler, hasMoreEvents, loadingCheck]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	return { loaderRef, events, eventsLoading, searchHandler, hasMoreEvents, error };
};
