import { useCallback } from "react";
import { ISendgridEmailTemplate } from "../../../interfaces/ISendgridTemplate";

export interface IUseMailTemplater {
	propsArray: () => string[];
	keysInTemplate: () => RegExpMatchArray | null;
}

/**
 * Hook to get both, sendgrid template keys and entity's object keys, that one could use in the email template
 *
 * @param entity Entity that we want to use in the template. Not tested with objects as value of the entity, will probably crash since
 * EmailTemaplter treats entity's values as string, since we are sending an email. Needs studying and maybe a depth control / more
 * depth to the selections.
 * @param template ISendgridEmailTemplate item we want to use in the mail
 * @returns propsArray represents the entitys object keys, keysInTemplate represents the keys that
 * sendgrid email template has to be filled
 */
export const useMailTemplater = function <TTemplater>(entity: TTemplater, template: ISendgridEmailTemplate): IUseMailTemplater {
	type keys = Array<keyof TTemplater>;
	const propsArray = useCallback(() => {
		return Object.keys(entity as unknown as keys);
	}, [entity])
	const keysInTemplate = useCallback(() => {
		const re = /(?<={{)(.*)(?=}})/g
		return template.plain_content.match(re);
	}, [template])

	return { propsArray, keysInTemplate }
}