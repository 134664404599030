import React from 'react';
import { IEezyTrusterEvent, IEezyTrusterPayment } from '../../../interfaces/IEezyTruster';
import { NETVISOR_PAYMENT_URL } from '../../../interfaces/INetvisorSettings';

interface IProps {
	payment: IEezyTrusterPayment;
	type: IEezyTrusterEvent["type"];
}

const PaymentEventIdDisplay: React.FC<IProps> = ({ payment, type }) => {
	return (payment.eezySalesOrderId && type === 'MANUAL') ?
		<a href={NETVISOR_PAYMENT_URL + payment.eezySalesOrderId} target="_blank" rel="noopener noreferrer">{payment.eezySalesOrderId}</a>
		:
		<span>{payment.eezySalesOrderId}
		</span>

};

export default PaymentEventIdDisplay;
