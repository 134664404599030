import React, { useEffect, useState } from "react";
import { ITemplateValues } from "./EmailTemplater";
import { ISendgridEmailTemplate } from "../../../interfaces/ISendgridTemplate";
import Button from "../../ui/Button/Button";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";

interface IProps<T = unknown> {
	mailTemplate: ISendgridEmailTemplate;
	values: ITemplateValues;
	item: T;
}

interface IPreviewState {
	previewState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export const EmailTemplaterPreviewFactory = <T extends any>({ previewState }: IPreviewState) => {
	const EmailTemplaterPreview: React.FC<IProps<T>> = ({ mailTemplate, values, item }) => {
		const { t } = useTranslation();

		const [content, setContent] = useState(mailTemplate.html_content);
		const [preview, setPreview] = previewState;
		const [showTokens, setShowTokens] = useState(false);

		useEffect(() => {
			if (!values || !item) return;
			let content = mailTemplate.html_content;
			if (showTokens) {
				setContent(content);
				return;
			}
			for (const key in values) {
				const templateVal = values[key];
				let val = "";
				if (templateVal.type === "token") {
					const tokenName = templateVal.value;
					type Keys = Array<keyof T>;
					const keys = Object.keys(item as unknown as Keys);
					for (const keyy of keys) {
						if (keyy.toString().toLowerCase() === tokenName.toLowerCase()) {
							val = item[keyy as keyof T] as unknown as string;
						}
					}
				} else {
					val = templateVal.value
				}
				content = content.replace(`{{${key}}}`, val);
			}
			setContent(content)
		}, [values, item, mailTemplate.html_content, showTokens])

		return <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
			<Button onClick={() => setPreview(t => !t)}>
				{preview ? t(ETranslation.EMAIL_TEMPLATE_STOP_PREVIEW) : t(ETranslation.EMAIL_TEMPLATE_PREVIEW)}
			</Button>
			{preview &&
				<>
					<Button onClick={() => setShowTokens(t => !t)}>
						{showTokens ? t(ETranslation.EMAIL_TEMPLATE_SHOW_FILLED) : t(ETranslation.EMAIL_TEMPLATE_SHOW_TOKENS)}
					</Button>
					<div dangerouslySetInnerHTML={{ __html: content }} />
				</>
			}
		</div>
	}
	return EmailTemplaterPreview;
}