import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import { ISummary, ISummarySearch, getSummariesClear, searchSummaries } from "../store/actions/summariesActions";

export const SummariesPagination = function () {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);

	const [searchOptions, setSearchOptions] = useState<ISummarySearch | null>(null);
	const [summaries, setSummaries] = useState<ISummary[] | null>(null);

	const [loadingCheck, setLoadingCheck] = useState(false);



	const { useSummaries, error, loading, hasNext } = useSelector(
		(state: IAppState) => ({
			useSummaries: state.summaries.summaries,
			loading: state.summaries.loading,
			error: state.summaries.error,
			hasNext: state.summaries.hasNext,
		})
	);

	const clearSummaries = () =>{
		dispatch(getSummariesClear())
	}

	useEffect(() => {
		setSummaries(useSummaries);
		setLoadingCheck(false);
	}, [useSummaries]);

	const searchHandler = useCallback((search: ISummarySearch) => {
		dispatch(searchSummaries(search));
		setSearchOptions(search);
	}, [dispatch]);


	const handleObserver = useCallback((entries) => {
		if (!hasNext || !searchOptions || loading || loadingCheck || !useSummaries || useSummaries?.length === 0) return;
		setLoadingCheck(true);
		const target = entries[0];
		if (target.isIntersecting) {
			searchHandler({
				...searchOptions,
				page: searchOptions?.page ?  searchOptions?.page + 1 : 1,
			});
		} else {
			setLoadingCheck(false);
		}
	}, [loading, useSummaries, searchHandler, searchOptions, hasNext, loadingCheck]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	return { loaderRef, summaries, loading, searchHandler, hasNext, error, clearSummaries };
};
