import React, { Suspense } from "react";
import Spinner from "../ui/Spinner/Spinner";
import ExcelViewer from "./ExcelViewer/ExcelViewer";
import WordViewer from "./WordViewer/WordViewer";
import PDFViewer from "./PDFViewer/PDFViewer";

export const isPDF = (contentType: string) => {
	return ["application/pdf"].includes(contentType);
};

export const isWord = (contentType: string) => {
	return [
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.template",
		"application/vnd.ms-word.document.macroenabled.12",
		"application/vnd.ms-word.template.macroenabled.12",
		"application/msword",
	].includes(contentType);
};

export const isExcel = (contentType: string) => {
	return [
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"application/vnd.ms-excel.sheet.macroEnabled.12",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.template",
		"application/vnd.ms-excel.template.macroEnabled.12",
		"application/vnd.ms-excel",
	].includes(contentType);
};

interface IProps {
	fileUrl: string;
	contentType: string;
}

const FilePreview: React.FC<IProps> = ({ fileUrl, contentType }) => {
	return (
		<Suspense fallback={<Spinner />}>
			{(() => {
				if (isPDF(contentType)) {
					return <PDFViewer fileUrl={fileUrl} />;
				} else if (isExcel(contentType)) {
					return <ExcelViewer fileUrl={fileUrl} />;
				} else if (isWord(contentType)) {
					return <WordViewer fileUrl={fileUrl} />;
				} else {
					return "Unknown contentType";
				}
			})()}
		</Suspense>
	);
};

export default FilePreview;
