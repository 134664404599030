import React, { useEffect, useRef } from "react";

interface IProps {
	fileUrl: string;
}

const PDFViewer: React.FC<IProps> = ({ fileUrl }) => {
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const loadPDF = async () => {
			const pdfjsLib = await import("pdfjs-dist");
			const pdfjsWorker = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();
			pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

			const pdf = await pdfjsLib.getDocument(fileUrl).promise;

            if (containerRef.current) {
                containerRef.current.innerHTML = "";

                for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);

                    const canvas = document.createElement("canvas");

                    const context = canvas.getContext("2d");
                    const viewport = page.getViewport({ scale: 1.5 });

                    canvas.width = viewport.width;
                    canvas.height = viewport.height;

					containerRef.current.appendChild(canvas);

					await page.render({
                        canvasContext: context!,
                        viewport: viewport,
                    }).promise;
                }
            }
		};
		loadPDF();
	}, [fileUrl]);

	return (
		<div
			ref={containerRef}
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "1rem",
				justifyContent: "center",
				alignItems: "center",
				background: "#F0F0F0",
				padding: '1rem 0'
			}}
		/>
	);
};

export default PDFViewer;
