import React, { useEffect, useState } from "react";
import { ITagLanguageVersions } from "../../store/actions/tagsActions";
import { EInputType, IInputField } from "../../components/ui/Input/Input";
import { useCreateInput } from "../../hooks/useCreateInput";
import Button, { EButtonColor } from "../../components/ui/Button/Button";
import classes from "./tags.module.scss";
import { ETranslation } from "../../translations/translation-keys";
import { useLanguages } from "../../hooks/useLanguages";
import { useTranslation } from "react-i18next";

interface IProps {
	tag: ITagLanguageVersions;
	removeTag: () => void;
	onUpdate: (inputs: IInputField) => void;
}

enum EInputs {
	description = "description",
	id = "id",
	language = "language",
	name = "name",
}

const TagLanguageVersion: React.FC<IProps> = ({ tag, removeTag, onUpdate }) => {
	const { t } = useTranslation();

	const { languageOptions } = useLanguages();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.id]: {
			type: EInputType.text,
			label: "id",
			value: tag?.id ?? null,
		},
		[EInputs.name]: {
			type: EInputType.text,
			labelTranslation: ETranslation.COMMON_NAME,
			value: tag?.name ?? "",
		},
		[EInputs.description]: {
			type: EInputType.textarea,
			labelTranslation: ETranslation.COMMON_DESCRIPTION,
			value: tag?.description ?? "",
		},
		[EInputs.language]: {
			type: EInputType.reactSelect,
			labelTranslation: ETranslation.COMMON_LANGUAGE,
			options: languageOptions,
			value: tag?.language ?? "",
		}
	});

	useEffect(() => {
		onUpdate(inputs);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputs]);

	const createInput = useCreateInput(inputs, setInputs);

	return (
		<div className={classes.tagLangTag}>
			{createInput(EInputs.language, {options: languageOptions})}
			{createInput(EInputs.name)}
			{createInput(EInputs.description)}
			<Button color={EButtonColor.DANGER} onClick={removeTag}>{t(ETranslation.COMMON_DELETE)}</Button>
		</div>
	)
}

export default TagLanguageVersion;