import { useCallback, useEffect } from "react";
import { IInputField } from "../components/ui/Input/Input";
import { initForm } from "../components/ui/Input/input-utils";
import { EStorageKeys } from "../shared/storage-keys";
import { useSelector } from "react-redux";
import IAppState from "../interfaces/store/IAppState";


interface IUseStoredSearchParamsProps<TFormOptions, TActions, TSearchParams> {
	setInputs: React.Dispatch<React.SetStateAction<IInputField>>;
	initialInputs: IInputField;
	storageKey: EStorageKeys;
	clearResults(): TActions;
	initIdInputs?: (data: TFormOptions) => void;
	searchHandler: (search: TSearchParams) => void;
	searchHandlerDataTransform: (data: TFormOptions) => TSearchParams;
}

export const useStoredSearchParams = function <TFormOptions, TActions, TSearchParam>({
	setInputs,
	initialInputs,
	storageKey,
	clearResults,
	initIdInputs,
	searchHandler,
	searchHandlerDataTransform
}: IUseStoredSearchParamsProps<TFormOptions, TActions, TSearchParam>) {
	const { user } = useSelector((state: IAppState) => state.auth);
	const onSearch = useCallback((searchParams: TFormOptions) => {
		if (clearResults) clearResults();
		searchHandler(searchHandlerDataTransform(searchParams));
		user?.storageHandler.setData(storageKey, searchParams);
	}, [searchHandler, clearResults, storageKey, searchHandlerDataTransform, user?.storageHandler]);

	useEffect(() => {
		const storageData = user?.storageHandler.getData(storageKey);
		if (storageData) {
			const data: TFormOptions = JSON.parse(storageData)
			initForm(setInputs, data);
			if (initIdInputs) initIdInputs(data);
		}
	}, [initIdInputs, setInputs, storageKey, user?.storageHandler])

	const clearInputs = () => {
		user?.storageHandler.clearData(storageKey);
		setInputs(initialInputs);
	};

	return { onSearch, clearInputs }
}