import { useEffect, useState } from "react";

import * as dateFns from 'date-fns';

/* USAGE EXAMPLE
<thead>
<TableSortContext.Provider value={{ onSort, sort }}>
  <tr>
    <th>Tila</th> // no sort
    <TableTh sort="user.firstName">Urheilija</TableTh> // sort by nested value
    <TableTh sort="startDate" type="date">Alkaa</TableTh> // sort by date
  </tr>
</TableSortContext.Provider>
</thead>
*/


interface IProps<T> {
  data: T[] | null;
}

export interface ISort {
  key: string | null;
  reverse: boolean;
  type: TSortType;
  format?: string;
}

export type TSortType = "string" | "int" | "date";

const getValue = <T>(key: string, item: T): string => {
	// Multipart column support. | as a separator, assumes a space between each element
	// Say for example name: firstName + " " + lastName scenario. Multiple parts are joined with a space
	if(key.indexOf("|") !== -1){
		const split = key.split("|");
		const retVal = split.map((osio)=>{
			return getValue(osio, item);
		}).join(" ");
		return retVal;
	}
  if (key.indexOf(".") !== -1) {
    const split = key.split(".");
    const part = split.shift() as string;
    return getValue(split.join('.'), item[part as keyof T]);
  }
  // For those unfilled columns and undefined datas...
  if(item !== undefined && item !== null){
	return item[key as keyof T] as unknown as string;
  } else {
	return "";
  }
}

export const useTableSort = <T>({ data }: IProps<T>) => {
  const [sort, setSort] = useState<ISort>({
    key: null,
    reverse: false,
    type: "string",
  });
  const [items, setItems ] = useState<T[]>([]);

  useEffect(() => {
	setItems(data ?? []);
  }, [data]);

  useEffect(() => {
    const { key, reverse, type, format } = sort;
    if(key) {
      setItems(items => {
        const newArray = [...items];

        newArray.sort((a, b) => {
          let aValue: string | number | Date = getValue(key, a);
          let bValue: string | number | Date = getValue(key, b);

          const multiplier = reverse ? -1 : 1;

          let aValueEmpty = !aValue || aValue.length === 0;
          let bValueEmpty = !bValue || bValue.length === 0;

          if (aValueEmpty && bValueEmpty) return 0;
          if (aValueEmpty) return -1 * multiplier;
          if (bValueEmpty) return 1 * multiplier;

          if (type === "date") {
			if(format) {
				aValue = dateFns.parse(aValue, format, new Date());
				bValue = dateFns.parse(bValue, format, new Date());
			} else {
				aValue = dateFns.parseISO(aValue);
				bValue = dateFns.parseISO(bValue);
			}
			/*
			* Compare the two dates and return 1 if the first date is after the second,
			* -1 if the first date is before the second or 0 if dates are equal
			* ref: https://date-fns.org/v2.15.0/docs/compareAsc
			*/
			return dateFns.compareAsc(aValue, bValue) * multiplier;
          } else if (type === "int") {
            aValue = parseInt(aValue as string);
            bValue = parseInt(bValue as string);
          }

          if (aValue > bValue) return 1 * multiplier;
          if (bValue > aValue) return -1 * multiplier;
          return 0;
        });

        return newArray;
      });
    }

  }, [sort]);


  const onSort = (key: string, type: TSortType, format?: string) => {
    setSort((prevSort) => {
      let reverse = false;
      if (prevSort.key === key) {
        reverse = !prevSort.reverse;
      }
      return {
        key,
        reverse,
        type,
		format,
      };
    });
  }


  return { sort, onSort, items };
}