import React, { useEffect, useState } from "react";
import classes from './emailtemplate.module.scss';
import { ISendgridEmailTemplate, ISendgridTemplateListItem } from "../../../interfaces/ISendgridTemplate";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import { getSendgridTemplate } from "../../../store/actions/sendgridTemplateActions";
import Spinner from "../../ui/Spinner/Spinner";
import Button from "../../ui/Button/Button";
import { ETranslation } from "../../../translations/translation-keys";
import { useTranslation } from "react-i18next";
import EmailTemplateSendLoading from "./EmailTemplateSendLoading";
import { EmailtTemaplterFactory } from "./EmailTemplater";
import EmailTemplateSelectInput from "./EmailTemplateSelectInput";


interface IProps<T = unknown> {
	items: T[];
	closeModal: (template: ISendgridEmailTemplate) => void;
}

export const EmailtTemaplteModalFactory = <T extends any>() => {
	const Instance: React.FC<IProps<T>> = ({ items, closeModal }) => {
		const dispatch = useDispatch();

		const {
			templatesLoading,
			template,
			templateLoading
		} = useSelector((state: IAppState) => state.sendgridTemplates);

		const { t } = useTranslation();

		const [selectedTemplateItem, setSelectedTemplateItem] = useState<null | ISendgridTemplateListItem>(null)
		const [step, setStep] = useState(1);

		// Set step & get template data when selected
		// Sendgrid templates have version history with them and there is suppose to be one with active: 1
		useEffect(() => {
			if (!selectedTemplateItem) return;
			setStep(2);
			const activeVersion = selectedTemplateItem.versions.find((item) => item.active === 1)
			if (!activeVersion) return;
			dispatch(getSendgridTemplate(selectedTemplateItem.id, activeVersion.id))
		}, [selectedTemplateItem, dispatch])


		const sendingCallBack = () => { setStep(3) }

		// Generic typed emailTemplater
		const Templater = EmailtTemaplterFactory<T>();

		const backToTemplateSelection = () => {
			setStep(s => s - 1)
			setSelectedTemplateItem(null);
		}

		if (templateLoading || templatesLoading) return <Spinner />
		const modalClose = ()=>{
			if(!template) return;
			closeModal(template)
		}

		return <div className={classes.modalContainer}>
			{step === 1 && <EmailTemplateSelectInput onSelect={setSelectedTemplateItem} />}
			{(step === 2 && template && !templateLoading) &&
				<>
					<Templater items={items} template={template} sending={sendingCallBack} />
					<Button onClick={backToTemplateSelection}>{t(ETranslation.COMMON_BACK)}</Button>
				</>
			}
			{step === 3 && <EmailTemplateSendLoading closeModal={modalClose} goBack={() => { setStep(s => s - 1) }} />}
		</div>
	}
	return Instance

}