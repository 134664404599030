import React from "react";
import { useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import Spinner from "../../ui/Spinner/Spinner";
import Button from "../../ui/Button/Button";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import classes from './emailtemplate.module.scss';

interface IProps {
	closeModal: () => void;
	goBack: () => void;
}

const EmailTemplateSendLoading: React.FC<IProps> = ({ closeModal, goBack }) => {
	const { sendMailLoading, sendMailError, sendMailOk } = useSelector((state: IAppState) => state.sendgridTemplates);
	const { t } = useTranslation();
	return <div className={classes.errorContainer}>
		{sendMailLoading &&
			<>
				<span>{t(ETranslation.EEZY_TRUSTER_STATUS_SENDING)}</span>
				<Spinner />
			</>
		}
		{sendMailError &&
			<>
				<span>{t(ETranslation.COMMON_ERROR)}: </span>
				<span>{sendMailError}</span>
				<Button onClick={goBack}>{t(ETranslation.COMMON_BACK)}</Button>
			</>
		}
		{sendMailOk &&
			<>
				<span>{t(ETranslation.EMAIL_TEMPLATE_SUCCESS_SENDING)}</span>
				<Button onClick={closeModal}>{t(ETranslation.COMMON_CLOSE)}</Button>
			</>
		}
	</div>
}

export default EmailTemplateSendLoading;