import EActionTypes from "../../interfaces/store/EActionTypes";
import IUserState from "../../interfaces/store/IUserState";
import IUserAction from "../../interfaces/store/IUserAction";
import { PAGINATION_FETCH_SIZE } from "../../hooks/useUserPagination";
import { ETranslation } from "../../translations/translation-keys";

type ReducerState = IUserState;
type ReducerSignature = (state: ReducerState, action: IUserAction) => ReducerState;

const initialState: ReducerState = {
	user: null,
	loading: false,
	error: null,
	users: null,
	usersLoading: false,
	paginationCursor: null,
	usersError: null,
	hasMoreUsers: false,
	searchParams: null,
	userType: null,
	organization: null,
	saveUserDone: false,
	saveUserLoading: false,
	passwordResetLoading: false,
	passwordResetError: null,
	passwordResetSuccess: false,
	message: null,
	resetEezyTrusterLoading: false,
	resetEezyTrusterError: null,
	resetEezyTrusterSuccess: false,
	userToNetvisorLoading: false,
	userToNetvisorError: null,
	contractSettings: null,
	contractSettingsLoading: false,
};

const getUserStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const getUsersStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, error: null, loading: true };
};

const getUserSuccess: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		user: action.user || null,
		error: null,
		loading: false,
	};
};

const getUsersSuccess: ReducerSignature = (state, action): ReducerState => {
	const hasMoreUsers = [...(action.users ?? [])].length === PAGINATION_FETCH_SIZE;
	const users = state.users
		? action.users
			? [...state.users, ...action.users]
			: null
		: action.users
		? action.users
		: null;
	return {
		...state,
		paginationCursor: action.paginationCursor || null,
		users: users,
		hasMoreUsers,
		error: null,
		loading: false,
	};
};

const getUserFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, user: null, loading: false, error: action.error };
};

const getUsersFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, user: null, loading: false, error: action.error };
};

const getUserClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, user: null, loading: false, error: null, saveUserDone: false };
};

const getUsersClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, users: null, loading: false, error: null, hasMoreUsers: false };
};

const saveUserStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, saveUserDone: false, saveUserLoading: true, error: null };
};

const saveUserDone: ReducerSignature = (state, action): ReducerState => {
	return { ...state, saveUserDone: true, saveUserLoading: false, error: null };
};

const saveUserError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, saveUserDone: false, saveUserLoading: false, error: action.error };
};

const resetSendPasswordStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, passwordResetLoading: true };
};

const resetSendPasswordSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, passwordResetLoading: false, passwordResetSuccess: true, message: action.message ?? "" };
};

const resetSendPasswordError: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		passwordResetLoading: false,
		passwordResetSuccess: false,
		passwordResetError: "Something went wrong.",
	};
};

const resetSendPasswordClear: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		passwordResetLoading: false,
		passwordResetSuccess: false,
		passwordResetError: null,
		message: null,
	};
};

// Reset Eezy Truster Id

const resetEezyTrusterIdStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, resetEezyTrusterLoading: true, resetEezyTrusterError: null, resetEezyTrusterSuccess: false };
};

const resetEezyTrusterIdSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, resetEezyTrusterLoading: false, resetEezyTrusterError: null, resetEezyTrusterSuccess: true };
};

const resetEezyTrusterIdError: ReducerSignature = (state, action): ReducerState => {
	return {
		...state,
		resetEezyTrusterLoading: false,
		resetEezyTrusterError: action.error ?? ETranslation.REDUCER_ERROR,
		resetEezyTrusterSuccess: false,
	};
};

const resetEezyTrusterIdClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, resetEezyTrusterLoading: false, resetEezyTrusterError: null, resetEezyTrusterSuccess: false };
};

const removeUserStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, saveUserLoading: true, error: null, saveUserDone: false };
};

const removeUserDone: ReducerSignature = (state, action): ReducerState => {
	return { ...state, saveUserLoading: false, error: null, saveUserDone: true };
};

const removeUserError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, saveUserLoading: false, error: null, saveUserDone: false };
};

const userToNetvisorStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, userToNetvisorLoading: true, userToNetvisorError: null };
};

const userToNetvisorDone: ReducerSignature = (state, action): ReducerState => {
	return { ...state, userToNetvisorLoading: false, userToNetvisorError: null };
};

const userToNetvisorError: ReducerSignature = (state, action): ReducerState => {
	return { ...state, userToNetvisorLoading: false, userToNetvisorError: action.error ?? ETranslation.REDUCER_ERROR };
};

const getUserContractSettingsStart: ReducerSignature = (state, action): ReducerState => {
	return { ...state, contractSettingsLoading: true };
};

const getUserContractSettingsSuccess: ReducerSignature = (state, action): ReducerState => {
	return { ...state, contractSettingsLoading: false, contractSettings: action.contractSettings || null };
};

const getUserContractSettingsFail: ReducerSignature = (state, action): ReducerState => {
	return { ...state, contractSettingsLoading: false };
};

const getUserContractSettingsClear: ReducerSignature = (state, action): ReducerState => {
	return { ...state, contractSettingsLoading: false, contractSettings: null };
};

const reducer = (state: ReducerState = initialState, action: IUserAction): ReducerState => {
	switch (action.type) {
		case EActionTypes.USER_GET_USER_START:
			return getUserStart(state, action);
		case EActionTypes.USER_GET_USER_SUCCESS:
			return getUserSuccess(state, action);
		case EActionTypes.USER_GET_USER_FAIL:
			return getUserFail(state, action);
		case EActionTypes.USER_GET_USER_CLEAR:
			return getUserClear(state, action);
		case EActionTypes.USER_GET_LIST_START:
			return getUsersStart(state, action);
		case EActionTypes.USER_GET_LIST_SUCCESS:
			return getUsersSuccess(state, action);
		case EActionTypes.USER_GET_LIST_FAIL:
			return getUsersFail(state, action);
		case EActionTypes.USER_GET_LIST_CLEAR:
			return getUsersClear(state, action);
		case EActionTypes.USER_SAVE_USER_SUCCESS:
			return saveUserDone(state, action);
		case EActionTypes.USER_RESET_SEND_PASSWORD_START:
			return resetSendPasswordStart(state, action);
		case EActionTypes.USER_RESET_SEND_PASSWORD_SUCCESS:
			return resetSendPasswordSuccess(state, action);
		case EActionTypes.USER_RESET_SEND_PASSWORD_ERROR:
			return resetSendPasswordError(state, action);
		case EActionTypes.USER_RESET_SEND_PASSWORD_CLEAR:
			return resetSendPasswordClear(state, action);
		case EActionTypes.USER_SAVE_USER_START:
			return saveUserStart(state, action);
		case EActionTypes.USER_SAVE_USER_ERROR:
			return saveUserError(state, action);
		case EActionTypes.USER_RESET_EEZY_TRUSTER_ID_START:
			return resetEezyTrusterIdStart(state, action);
		case EActionTypes.USER_RESET_EEZY_TRUSTER_ID_SUCCESS:
			return resetEezyTrusterIdSuccess(state, action);
		case EActionTypes.USER_RESET_EEZY_TRUSTER_ID_ERROR:
			return resetEezyTrusterIdError(state, action);
		case EActionTypes.USER_RESET_EEZY_TRUSTER_ID_CLEAR:
			return resetEezyTrusterIdClear(state, action);
		case EActionTypes.USER_REMOVE_START:
			return removeUserStart(state, action);
		case EActionTypes.USER_REMOVE_SUCCESS:
			return removeUserDone(state, action);
		case EActionTypes.USER_REMOVE_ERROR:
			return removeUserError(state, action);
		case EActionTypes.USER_MOVE_TO_NETVISOR_START:
			return userToNetvisorStart(state, action);
		case EActionTypes.USER_MOVE_TO_NETVISOR_SUCCESS:
			return userToNetvisorDone(state, action);
		case EActionTypes.USER_MOVE_TO_NETVISOR_ERROR:
			return userToNetvisorError(state, action);
		case EActionTypes.USER_GET_CONTRACT_SETTINGS_START:
			return getUserContractSettingsStart(state, action);
		case EActionTypes.USER_GET_CONTRACT_SETTINGS_SUCCESS:
			return getUserContractSettingsSuccess(state, action);
		case EActionTypes.USER_GET_CONTRACT_SETTINGS_FAIL:
			return getUserContractSettingsFail(state, action);
		case EActionTypes.USER_GET_CONTRACT_SETTINGS_CLEAR:
			return getUserContractSettingsClear(state, action);

		default:
			return state;
	}
};

export default reducer;
