import React from "react";
import { IUserLanguagePair } from "../../../interfaces/IUserLanguagePair";
import Button, { EButtonSize } from "../../ui/Button/Button";
import UserLanguagePair from "./UserLanguagePair/UserLanguagePair";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";

interface IProps {
	userLanguagePairs: IUserLanguagePair[];
	onAdd: VoidFunction;
	onRemove: (id: string) => void;
	onChange: (userLanguage: IUserLanguagePair, isValid: boolean) => void;
	disabled?: boolean;
}

const UserLanguagePairs: React.FC<IProps> = ({ onAdd, userLanguagePairs, onRemove, onChange, disabled }) => {
	const { t } = useTranslation();
	return (
		<>
			{!disabled && (
				<Button onClick={onAdd} size={EButtonSize.SMALL}>
					{t(ETranslation.COMMON_ADD_NEW)}
				</Button>
			)}
			{userLanguagePairs.map((pair) => (
				<UserLanguagePair key={pair.id} userLanguagePair={pair} onChange={onChange} onRemove={onRemove} disabled={disabled} />
			))}
		</>
	);
};

export default UserLanguagePairs;
