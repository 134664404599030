import React, { useCallback, useState } from 'react';
import Button, { EButtonColor } from '../../components/ui/Button/Button';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';
import { EInputType, IInputField } from '../../components/ui/Input/Input';
import { useCreateInput } from '../../hooks/useCreateInput';
import { searchOrganizations } from '../../services/searchServices';
import { getInputData, initForm } from '../../components/ui/Input/input-utils';
import { useStoredSearchParams } from '../../hooks/useStoredSearchParams';
import IOrganizationAction from '../../interfaces/store/IOrganizationAction';
import { findOrganizationsClear } from '../../store/actions/organizationActions';
import { useInitSearchValues } from '../../hooks/useInitSearchValues';
import { getOrganizationOptions } from '../../services/organizationServices';
import { useDispatch } from 'react-redux';
import { EOrganizationTypes, IPaginationOptions, organizationTypes } from '../../hooks/useOrganizationPagination';
import { getOrganizationGroupOptions, searchOrganizationGroups } from '../../services/organizationGroupService';
import { EStorageKeys } from '../../shared/storage-keys';

interface IProps {
	searchHandler: (search: IPaginationOptions) => void
	loading: boolean;
}

enum EInputs {
	organization = "organization",
	type = "type",
	organizationGroup = "organizationGroup",
};

export interface IFilterOptions {
	organization: string;
	type: EOrganizationTypes[],
	organizationGroup: string,
}
const initialInputs = {
	[EInputs.organization]: {
		type: EInputType.reactSelectSearch,
		labelTranslation: ETranslation.COMMON_ORGANIZATION,
		value: [],
		multiple: false,
	},
	[EInputs.type]: {
		type: EInputType.reactSelect,
		labelTranslation: ETranslation.COMMON_TYPE,
		value: [],
		multiple: true,
		options: organizationTypes,
	},
	[EInputs.organizationGroup]: {
		type: EInputType.reactSelectSearch,
		labelTranslation: ETranslation.COMMON_ORGANIZATION_GROUP,
		value: [],
		multiple: false,
	},
}

const OrganizationsSearch: React.FC<IProps> = ({ searchHandler, loading }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [inputs, setInputs] = useState<IInputField>(initialInputs);
	const { setIds: setOrganizationIds, isLoading: isLoadingOrganizations } = useInitSearchValues(setInputs, EInputs.organization, getOrganizationOptions);
	const { setId: setOrganizationGroupId, isLoading: isLoadingOrganizationGroupss } = useInitSearchValues(setInputs, EInputs.organizationGroup, getOrganizationGroupOptions);

	const { onSearch, clearInputs } = useStoredSearchParams<IFilterOptions, IOrganizationAction, IPaginationOptions>({
		setInputs,
		initialInputs,
		storageKey: EStorageKeys.ORGANIZATION_SEARCH_PARAMS_V2,
		clearResults: useCallback(() => dispatch(findOrganizationsClear()), [dispatch]),
		searchHandlerDataTransform: useCallback((data: IFilterOptions) => { return { page: 1, id: data.organization, organizationGroup: data.organizationGroup ?? null, type: data.type ?? null } }, []),
		searchHandler,
		initIdInputs: useCallback((data: IFilterOptions) => {
			initForm(setInputs, { ...data, organization: [] });
			if (data.organization && data.organization.length > 0) setOrganizationIds([data.organization])
			if (data.organizationGroup && data.organizationGroup.length > 0) setOrganizationGroupId(data.organizationGroup)
		}, [setOrganizationIds, setOrganizationGroupId])
	})
	const createInput = useCreateInput(inputs, setInputs);

	return (
		<>
			{createInput(EInputs.organization, { fetchOptions: searchOrganizations, isLoading: isLoadingOrganizations })}
			{createInput(EInputs.organizationGroup, { fetchOptions: searchOrganizationGroups, isLoading: isLoadingOrganizationGroupss })}
			{createInput(EInputs.type)}
			<Button loading={loading} onClick={() => { onSearch(getInputData<IFilterOptions>(inputs)) }}>{t(ETranslation.COMMON_SEARCH)}</Button>
			<Button loading={loading} color={EButtonColor.DEFAULT} onClick={clearInputs}>{t(ETranslation.UI_CLEAR)}</Button>
		</>
	);
};

export default OrganizationsSearch;
