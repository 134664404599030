import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IAppState from "../../../interfaces/store/IAppState";
import { ETranslation } from "../../../translations/translation-keys";
import { IInputField, EInputType } from "../../ui/Input/Input";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { getSendgridTemplatesList } from "../../../store/actions/sendgridTemplateActions";
import { getInputData } from "../../ui/Input/input-utils";
import { ISendgridTemplateListItem } from "../../../interfaces/ISendgridTemplate";


interface IProps {
	onSelect: (template: ISendgridTemplateListItem) => void;
}

const EInputs = {
	mailtemplate: "mailtemplate",
}

const EmailTemplateSelectInput: React.FC<IProps> = ({ onSelect }) => {
	const dispatch = useDispatch();

	const {
		templates,
		templatesLoading,
		templatesLoadingOk,
	} = useSelector((state: IAppState) => state.sendgridTemplates);

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.mailtemplate]: {
			type: EInputType.select,
			labelTranslation: ETranslation.EMAIL_TEMPLATE_SELECT_TEMPLATE,
			value: "",
		},
	})

	const createInput = useCreateInput(inputs, setInputs);

	const updateTemplatesInput = useCallback(() => {
		setInputs(inputs => {
			return {
				...inputs,
				[EInputs.mailtemplate]: {
					...inputs[EInputs.mailtemplate],
					options: templates?.map((item) => { return { label: item.name, value: item.id } }),
				},
			}
		})
	}, [templates])

	// Get templates
	useEffect(() => {
		if (templatesLoading || templates) return;
		dispatch(getSendgridTemplatesList());
	}, [templates, templatesLoading, dispatch])

	// effect to update templates input
	useEffect(() => {
		if (!templatesLoadingOk || !templates) return;
		updateTemplatesInput();
	}, [templatesLoadingOk, templates, updateTemplatesInput])

	// Selection effect
	useEffect(() => {
		const { mailtemplate } = getInputData<{ mailtemplate: string }>(inputs);
		if (!mailtemplate || !templates) return;
		const selectedTemplate = templates.find((item) => item.id === mailtemplate);
		if (!selectedTemplate) return;
		onSelect(selectedTemplate);
	}, [inputs, templates, onSelect])

	return <>
		{createInput(EInputs.mailtemplate, { isLoading: templatesLoading })}
	</>
}

export default EmailTemplateSelectInput;