import React from "react";

import { IAttribute } from "../../interfaces/IAttribute";
import Heading from "../Heading/Heading";
import Button, { EButtonSize } from "../ui/Button/Button";
import AttributesAttachList from "./AttributesAttachList";
import { useAttributeSelectModal } from "./useAttributeSelectModal";
import { ECategoryType } from "../../shared/category-data";
import BadgeList from "../ui/BadgeList/BadgeList";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";

interface IProps {
	attributes: IAttribute[];
	onAdd: (attribute: IAttribute) => void;
	onDelete: (id: string) => void;
	onEdit: (attribute: IAttribute) => void;
	showTitle?: boolean;
	disabled?: boolean;
	types?: ECategoryType[];
	showBadges?: boolean;
}

const AttributesAttach: React.FC<IProps> = ({
	attributes,
	onAdd,
	onDelete,
	onEdit,
	showTitle,
	disabled,
	types = [
		ECategoryType.INTERPRETATION_SEARCH,
		ECategoryType.INTERPRETATION_SUBTYPE,
	],
	showBadges = false,
}) => {
	const openAttributeSelectModal = useAttributeSelectModal();
	const { t } = useTranslation();

	const addAttributeHandler = async () => {
		const attr = await openAttributeSelectModal(
			attributes,
			t(ETranslation.ATTRIBUTES_ADD_ATTRIBUTE),
			types
		);
		if (attr) {
			onAdd(attr);
		}
	};

	return (
		<>
			{showTitle && <Heading tag="h3">{t(ETranslation.MENU_ATTRIBUTES)}</Heading>}
			<div style={{ marginBottom: "1rem" }}>
				{!disabled && (
					<Button
						onClick={addAttributeHandler}
						size={EButtonSize.SMALL}
					>
						{t(ETranslation.ATTRIBUTES_ADD_ATTRIBUTE)}
					</Button>
				)}
				{showBadges ? (
					<BadgeList
						items={attributes}
						onDelete={disabled ? undefined : onDelete}
					/>
				) : (
					<AttributesAttachList
						attributes={attributes}
						onEdit={onEdit}
						onDelete={onDelete}
						disabled={disabled}
					/>
				)}
			</div>
		</>
	);
};

export default AttributesAttach;
