import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import Logo from '../Logo/Logo';
import classes from './Header.module.scss';
import Menu, { EMenuType } from './Menu/Menu';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen((prevState) => !prevState);

  return (
    <React.Fragment>
      <Menu isOpen={isOpen} toggleMenu={toggleMenu} type={EMenuType.MOBILE} />
      <header className={classes.Container}>
        <div className={classes.Wrapper}>
          <div className={classes.ToggleMenu} onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} className={classes.Icon} />
          </div>
          <div className={classes.Logo}>
            <Logo />
          </div>
          <Menu isOpen={false} toggleMenu={() => {}} type={EMenuType.DESKTOP} />
        </div>
      </header>
    </React.Fragment>
  );
};

export default React.memo(Header);
