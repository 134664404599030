import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useLocation } from "react-router-dom";

import Alert from "../../../components/ui/Alert/Alert";
import {
	IInterpretation,
	IInterpretationIndexItem,
} from "../../../interfaces/IInterpretation";
import IAppState from "../../../interfaces/store/IAppState";
import { EInterpretationType } from "../../../shared/interpretation-data";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import * as dateFns from "date-fns";
import classes from "./OrderInterpretationPage.module.scss";
import { ERoute, Routes } from "../../../classes/Routes";
import IAuthState from "../../../interfaces/store/IAuthState";
import { InterpretationContext } from "./order-interpretation-context";
import OrderInterpretationInstant from "../../../components/Interpretation/OrderInterpretation/OrderInterpretationInstant";
import OrderInterpretationType from "../../../components/Interpretation/OrderInterpretation/OrderInterpretationType";
import ContainerRow from "../../../components/ui/Container/ContainerRow/ContainerRow";
import ContainerCol from "../../../components/ui/Container/ContainerCol/ContainerCol";
import OrderInterpretationSummary from "../../../components/Interpretation/OrderInterpretation/OrderSummary/OrderInterpretationSummary";
import OrderInterpretationBookingAdmin from "../../../components/Interpretation/OrderInterpretation/OrderInterpretationBookingAdmin";
import OrderInterpretationBookingCustomer from "../../../components/Interpretation/OrderInterpretation/OrderInterpretationBookingCustomer";
import OrderInterpretationOnSite from "../../../components/Interpretation/OrderInterpretation/OrderInterpretationOnSite";
import HeadingBox from "../../../components/Heading/HeadingBox/HeadingBox";
import OrderInterpretationAvailableInterpreters from "../../../components/Interpretation/OrderInterpretation/OrderInterpretationAvailableInterpreters";
import OrderButtons from "../../../components/ui/EditButtons/OrderButtons";
import Spinner from "../../../components/ui/Spinner/Spinner";
import OrderConfirmedModal from "../../../components/Interpretation/OrderConfirmedModal/OrderConfirmedModal";
import ModalContext, {
	EModalSize,
} from "../../../components/ui/Modal/ModalContext";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";

interface IMatch {
	id: string;
	type: string;
}

interface IProps extends RouteComponentProps<IMatch> { }

const OrderInterpretationPage: React.FC<IProps> = ({ match, history }) => {
	const [editInterpretation, setEditInterpretation] = useState<IInterpretation | null>(null);
	const [isValid, setIsValid] = useState<boolean>(false);
	const [allInterpretationsValid, setAllInterpretationsValid] = useState<boolean>(false);
	const [showValidation, setShowValidation] = useState<boolean>(false);
	const location = useLocation();
	const searchParams = useMemo(() => {
		if (!location.search) return;
		return new URLSearchParams(location.search)
	}, [location])

	const [interpretationType, setInterpretationType] = useState<string | undefined>(undefined);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [currentAlternativeIndex, setCurrentAlternativeIndex] = useState(0);
	//const [invalidIndexes, setInvalidIndexes] = useState<number[]>([]);
	const [invalidIndexItems, setInvalidIndexItems] = useState<IInterpretationIndexItem[]>([]);
	const [orderInterpretationsList, setOrderInterpretationsList] = useState<IInterpretation[]>([]);
	const [showAlternativeOnly, setShowAlternativeOnly] = useState(false);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { user } = useSelector<IAppState, IAuthState>((state) => state.auth);
	const { setModal, closeModal } = useContext(ModalContext);
	const [showOrderSuccess, setShowOrderSuccess] = useState(false);
	const {
		error,
		loading,
		instantInterpretation,
		organization,
		organizationLoading,
	} = useSelector((state: IAppState) => ({
		loading: state.interpretation.loading,
		error: state.interpretation.error,
		instantInterpretation: state.interpretation.instantInterpretation,
		organization: state.organization.organization,
		organizationLoading: state.organization.organizationLoading,
	}));

	useEffect(() => {
		if (!user?.organizations) return;
		if (!user.isYoupretAdmin) {
			dispatch(actions.getOrganization(user.organizations[0].id));
		}
	}, [dispatch, user]);

	useEffect(() => {
		return () => {
			dispatch(actions.saveOrUpdateInterpretationClear());
			dispatch(actions.requestInterpretationClear());
			dispatch(actions.getInterpretationClear());
			setOrderInterpretationsList([]);
		};
	}, [dispatch]);

	useEffect(() => {
		if (!searchParams) return;
		const type = searchParams.get("type")
		if (!type) return;
		setInterpretationType(type);
	}, [searchParams])

	const clearHandler = () => {
		dispatch(actions.saveOrUpdateInterpretationClear());
		dispatch(actions.requestInterpretationClear());
		dispatch(actions.getInterpretationClear());
		setOrderInterpretationsList([]);
		setAllInterpretationsValid(true);
		setInvalidIndexItems([]);
	};

	const onClear = useCallback(() => {
		setEditInterpretation(null);
		setInterpretationType(undefined);
		setAllInterpretationsValid(true);
		setShowValidation(false);
		setOrderInterpretationsList([]);
		setCurrentIndex(0);
		setInvalidIndexItems([]);
	}, []);

	const onChangeInterpretationType = useCallback(
		(interpretation: IInterpretation) => {
			setEditInterpretation(interpretation);
			setInterpretationType(interpretation.type);
			setOrderInterpretationsList([interpretation]);
			setShowAlternativeOnly(false)
		},
		[]
	);

	useEffect(() => {
		if (!instantInterpretation) return;
		const id = instantInterpretation.interpretation.id;
		history.push(Routes.withParams(ERoute.INTERPRETATION_PROCESSING, { id }));
	}, [history, instantInterpretation]);

	const updateHandler = useCallback(
		(
			interpretation: IInterpretation,
			isValid: boolean,
			index?: number,
			altIndex?: number,
			isAlternative?: boolean
		) => {
			setIsValid(isValid);
			setEditInterpretation(interpretation);
			if (index != null) {
				setCurrentIndex(index);
			}
			if (altIndex != null) {
				setCurrentAlternativeIndex(altIndex);
			}
			if (isAlternative != null) {
				setShowAlternativeOnly(isAlternative);
			}
		},
		[]
	);

	const formatDate = (date?: string) => {
		if (date) return dateFns.format(dateFns.parseISO(date), "dd.MM.yyyy HH:mm");
	};

	const submitHandler = async () => {
		const list = [...orderInterpretationsList];
		if (
			interpretationType !== EInterpretationType.INSTANT
		) {
			list.forEach((interpretation) => {
				if (interpretation.startDate && interpretation.startTime) {
					interpretation.startDate = formatDate(
						interpretation.startDate + " " + interpretation.startTime
					);
				}
			});
		}
		if (
			interpretationType === EInterpretationType.BOOKING ||
			interpretationType === EInterpretationType.ON_SITE
		) {
			list.forEach((interpretation) => {
				dispatch(actions.saveInterpretation(interpretation));
			});
			setShowOrderSuccess(true);
			onClear();
			clearHandler();
		} else if (
			interpretationType === EInterpretationType.INSTANT
		) {
			if (!editInterpretation) return;
			dispatch(actions.requestInterpretation(editInterpretation));
			onClear();
			clearHandler();
		}
	};

	useEffect(() => {
		if (currentIndex == null || !editInterpretation) return;
		const list = [...orderInterpretationsList];
		editInterpretation.isValid = isValid;
		if (!editInterpretation.isAlternative) {
			list[currentIndex] = editInterpretation;
		} else {
			const interpretation = { ...list[currentIndex] };
			interpretation.alternatives[currentAlternativeIndex] = editInterpretation;
			list[currentIndex] = interpretation;
		}
		setOrderInterpretationsList(list);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentIndex, isValid, editInterpretation]);

	// useEffect(() => {
	//   const invalidIndexes: number[] = []
	//   for (let i = 0; i < orderInterpretationsList.length; i++) {
	//     const interpretation = orderInterpretationsList[i];
	//     if(interpretation.isValid && interpretation.isValid === undefined) return;
	//     if(interpretation.isValid === false) {
	//       setAllInterpretationsValid(false)
	//       orderInterpretationsList.forEach((element, index) => {
	//         if(element.isValid === false) {
	//           invalidIndexes.push(index)
	//         }
	//       });
	//       setInvalidIndexes(invalidIndexes)
	//       break;
	//     }else if(interpretation.isValid) {
	//       setAllInterpretationsValid(true)
	//     }
	//   }
	// }, [orderInterpretationsList])

	useEffect(() => {
		const invalidIndexItems: IInterpretationIndexItem[] = [];
		for (let i = 0; i < orderInterpretationsList.length; i++) {
			const interpretation = orderInterpretationsList[i];
			const IndexItem: IInterpretationIndexItem = {
				index: i,
				isValid: interpretation.isValid,
				invalidIndexed: [],
			};
			if (interpretation.alternatives) {
				interpretation.alternatives.forEach((altElement, altIndex) => {
					if (altElement.isValid === false) {
						IndexItem.invalidIndexed.push(altIndex);
					}
				});
			}
			invalidIndexItems.push(IndexItem);
			setInvalidIndexItems(invalidIndexItems);
		}
	}, [orderInterpretationsList]);

	useEffect(() => {
		for (let i = 0; i < invalidIndexItems.length; i++) {
			const indexItem = invalidIndexItems[i];
			if (indexItem.isValid === false) {
				setAllInterpretationsValid(false)
				break;
			} else if (indexItem.invalidIndexed.length !== 0) {
				setAllInterpretationsValid(false);
				break;
			} else {
				setAllInterpretationsValid(true);
			}
		}
	}, [invalidIndexItems]);

	useEffect(() => {
		if (showOrderSuccess) {
			setModal({
				isOpen: showOrderSuccess,
				size: EModalSize.EXTRASMALL,
				content: <OrderConfirmedModal />,
				onModalClose: () => {
					setShowOrderSuccess(false);
				},
			});
		}
	}, [setModal, showOrderSuccess, t]);

	const showOrderConfirmationModal = () => {
		if (
			interpretationType !== EInterpretationType.INSTANT
		) {
			if (allInterpretationsValid) {
				const interpretationsAmount = orderInterpretationsList.length;
				let interpretationConfirmModalText =
					t(ETranslation.MODAL_ARE_YOU_SURE_ORDER) + interpretationsAmount;
				if (interpretationsAmount > 1) {
					interpretationConfirmModalText += t(
						ETranslation.MODAL_AMOUNT_OF_TRANSLATIONS
					);
				} else {
					interpretationConfirmModalText += t(
						ETranslation.MODAL_AMOUNT_ONE_TRANSLATION
					);
				}
				setModal({
					isOpen: true,
					size: EModalSize.EXTRASMALL,
					content: (
						<ConfirmationModal
							text={interpretationConfirmModalText}
							onConfirm={() => {
								submitHandler();
								closeModal();
							}}
						/>
					),
				});
			} else {
				setShowValidation(true);
			}
		} else {
			if (allInterpretationsValid) {
				submitHandler();
			} else {
				setShowValidation(true);
			}
		}
	};

	const removeInterpretationFromList = useCallback((indexNumber: number) => {
		setOrderInterpretationsList((list) =>
			list.filter((i, index) => index !== indexNumber)
		);
	}, []);

	const removeAlternativeInterpretationFromList = useCallback(
		(indexNumber: number, alternativeIndexNumber: number) => {
			const list = [...orderInterpretationsList];
			const interpretation = list[indexNumber];
			const filtered = interpretation.alternatives.filter(
				(i, index) => index !== alternativeIndexNumber
			);
			interpretation.alternatives = filtered;
			list[indexNumber] = interpretation;
			setOrderInterpretationsList(list);
		},
		[orderInterpretationsList]
	);

	return (
		<>
			<ContainerRow>
				<ContainerCol>
					{error && <Alert>{error}</Alert>}
					{loading && <Spinner center />}
					<HeadingBox>{t(ETranslation.MENU_ORDER_INTERPRETER)}</HeadingBox>
					{!instantInterpretation?.interpretation.id && (
						<OrderInterpretationType
							onChange={onChangeInterpretationType}
							loading={organizationLoading}
							organization={organization}
							showValidation={showValidation}
						/>
					)}
					<InterpretationContext.Provider
						value={{
							onChange: updateHandler,
							onClear: onClear,
							removeInterpretation: removeInterpretationFromList,
							removeAlternativeInterpretation:
								removeAlternativeInterpretationFromList,
							loading,
							organization,
							showValidation,
							editInterpretation,
							alternativeOnly: showAlternativeOnly,
							currentIndexValue: currentIndex,
							currentAlternativeIndexValue: currentAlternativeIndex,
							showNotValid: showValidation && !allInterpretationsValid,
							invalidIndexItems: invalidIndexItems,
						}}
					>
						{(() => {
							switch (interpretationType) {
								case EInterpretationType.INSTANT:
									return <OrderInterpretationInstant />;
								case EInterpretationType.BOOKING:
									if (user?.isYoupretAdmin)
										return <OrderInterpretationBookingAdmin />;
									if (!user?.isYoupretAdmin)
										return <OrderInterpretationBookingCustomer />;
									break;
								case EInterpretationType.ON_SITE:
									return <OrderInterpretationOnSite />;
							}
						})()}
					</InterpretationContext.Provider>
					{interpretationType && (
						<div className={classes.AlignButtonsCenter}>
							<OrderButtons
								onSend={showOrderConfirmationModal}
								onSendText={
									orderInterpretationsList.length > 1
										? t(ETranslation.INTERPRETATION_ORDER_MULTIPLE)
										: undefined
								}
								disabled={showValidation && !allInterpretationsValid}
								loading={loading}
							/>
						</div>
					)}
				</ContainerCol>
				<ContainerCol dontScaleHeight sticky>
					<OrderInterpretationSummary
						currentIndex={currentIndex}
						currentAlternativeIndex={currentAlternativeIndex}
						interpretations={orderInterpretationsList}
						organization={organization}
						showAlternativeOnly={showAlternativeOnly}
						setAlternativeOnly={(value) => setShowAlternativeOnly(value)}
						setCurrentIndex={(index, altIndex) => {
							setCurrentIndex(index)
							setCurrentAlternativeIndex(altIndex)
						}}
					/>
				</ContainerCol>
				{user?.isYoupretAdmin && (
					<ContainerCol dontScaleHeight sticky>
						<OrderInterpretationAvailableInterpreters
							interpretation={editInterpretation}
						/>
					</ContainerCol>
				)}
			</ContainerRow>
		</>
	);
};

export default OrderInterpretationPage;
