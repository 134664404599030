import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import IAppState from "../interfaces/store/IAppState";
import { ILead } from "../interfaces/ILead";
import { getLeads } from "../store/actions/leadsActions";


export const PAGINATION_FETCH_SIZE = 20;

export interface ILeadsFetchOptions {
	start: number;
	fetchSize: number;
}

export const LeadsListPagination = function () {
	const dispatch = useDispatch();
	const loaderRef = useRef<HTMLParagraphElement>(null);

	const [leads, setLeads] = useState<ILead[] | null>(null);

	const [loadingCheck, setLoadingCheck] = useState(false);

	const {
		leads: stateLeads,
		leadsLoading,
		leadsError: error,
		hasMoreLeads,
	} = useSelector((state: IAppState) => state.leads);

	useEffect(() => {
		setLeads(stateLeads);
		setLoadingCheck(false);
	}, [stateLeads]);

	const searchHandler = useCallback((data: ILeadsFetchOptions) => {
		dispatch(getLeads(data));
	}, [dispatch]);

	const handleObserver = useCallback((entries) => {
		if (!hasMoreLeads || leadsLoading || loadingCheck || !stateLeads || stateLeads?.length === 0) return;
		const target = entries[0];
		if (target.isIntersecting) {
			setLoadingCheck(true);
			searchHandler({
				start: stateLeads.length,
				fetchSize: PAGINATION_FETCH_SIZE,
			});
		} else {
			setLoadingCheck(false);
		}
	}, [leadsLoading, stateLeads, searchHandler, hasMoreLeads, loadingCheck]);

	useEffect(() => {
		const observer = new IntersectionObserver(handleObserver, {
			root: null,
			rootMargin: "",
			threshold: 1,
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [handleObserver]);

	return { loaderRef, leads, leadsLoading, searchHandler, hasMoreLeads, error };
};
