import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EInputType, IInputField } from "../ui/Input/Input";
import { useCreateInput } from "../../hooks/useCreateInput";
import { ETranslation } from "../../translations/translation-keys";
import Button from "../ui/Button/Button";

interface IProps {
	onAdd(comment: string): void;
}

enum EInputs {
	value = "value",
};

const CommentingElement: React.FC<IProps> = ({ onAdd }) => {
	const { t } = useTranslation();

	const [inputs, setInputs] = useState<IInputField>({
		[EInputs.value]: {
			type: EInputType.textarea,
			value: "",
			label: "",
			placeholder: t(ETranslation.COMMENTS_ADD_NEW_COMMENT),
		},
	});
	const createInput = useCreateInput(inputs, setInputs);

	const value = inputs[EInputs.value].value as string;

	return (
		<>
			{createInput(EInputs.value)}
			<Button disabled={value.length === 0} onClick={() => onAdd(value)}>{t(ETranslation.UI_SAVE)} </Button>
		</>
	)
}

export default CommentingElement;