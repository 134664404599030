import React, { Dispatch, SetStateAction } from 'react';

import { useCreateInput } from '../../hooks/useCreateInput';
import { IUserLanguagePair } from '../../interfaces/IUserLanguagePair';
import Fieldset from '../ui/Fieldset/Fieldset';
import { IInputField } from '../ui/Input/Input';
import UserLanguagePairs from '../User/UserLanguagePairs/UserLanguagePairs';
import { EJobApplicationUserLanguagesInput } from './useJobApplicationLanguageInputs';
import { useTranslation } from 'react-i18next';
import { ETranslation } from '../../translations/translation-keys';

interface IProps {
	inputs: IInputField;
	setInputs: Dispatch<SetStateAction<IInputField>>;
	disabled?: boolean;
	onUserLanguagePairAdd: VoidFunction,
	onUserLanguagePairChange: (languagePair: IUserLanguagePair, isValid: boolean) => void,
	onUserLanguagePairRemove: (id: string) =>  void,
	userLanguagePairs: IUserLanguagePair[];
}

const JobApplicationLanguages: React.FC<IProps> = ({
	inputs,
	setInputs,
	disabled,
	onUserLanguagePairAdd,
	onUserLanguagePairChange,
	onUserLanguagePairRemove,
	userLanguagePairs
}) => {
	const { t } = useTranslation();
	const createInput = useCreateInput(inputs, setInputs, { disabled });
	return (
		<Fieldset label={t(ETranslation.COMMON_LANGUAGES)}>
			<p style={{ marginTop: 0 }}>
				<i>
					{t(ETranslation.APPLICATIONS_LANGUAGES_INFO)}
				</i>
			</p>
			{createInput(EJobApplicationUserLanguagesInput.userLanguagesText)}
			<UserLanguagePairs
				onAdd={onUserLanguagePairAdd}
				onChange={onUserLanguagePairChange}
				onRemove={onUserLanguagePairRemove}
				userLanguagePairs={userLanguagePairs}
				disabled={disabled}
			/>
		</Fieldset>
	);
};

export default React.memo(JobApplicationLanguages);
